import React from 'react';
import styled from 'styled-components';


const BlueGradientSection = ({ children }) => {
  return (
    <Wrapper>{children}</Wrapper>
  );
}

const Wrapper = styled.section`
  background: rgb(13,18,70);
  background: radial-gradient(circle, rgba(13,18,70,1) 0%, rgba(1,4,42,1) 100%);
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export default BlueGradientSection;
