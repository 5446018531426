import React from 'react';
import styled from 'styled-components';
import { Container } from '../../styles/variables';

const LeftSideSection = ({ className, transparent = false, children }) => {
  return (
    <Wrapper transparent={transparent} className={className}>{children}</Wrapper>
  );
}

const Wrapper = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 60px;
  border-radius: 4px;
  z-index: 20;
  text-align: left;
  position: relative;

  h2 {
    max-width: 70%;
    line-height: 72px;
    margin-bottom: 30px;
  }

  p {
    max-width: 60%;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 800px){
    h2{
      line-height: normal;
    }
  }

  @media only screen and (max-width: 690px){
    padding: 0;

    h2{
      max-width: none;
    }
    p{
      max-width: none;
    }
  }
`;

export default LeftSideSection;
