import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class Header extends Component {

  render() {
    const { isBackgroundVideo, background, children, fullHeight, centeredBackground, compactHeader } = this.props;

    const videoBackground = (
      <Underlay>
        <video autoPlay muted loop>
          <source src={background} type="video/mp4"/>
        </video>
      </Underlay>
    )

    return (
      <HeaderContainer background={!isBackgroundVideo && background}  fullHeight={fullHeight} centeredBackground={centeredBackground} compactHeader={compactHeader}>
        { isBackgroundVideo ? videoBackground : null }
        { children }
      </HeaderContainer>
    );
  }

}

const HeaderContainer = styled.section`
  display: flex;
  justify-content: ${props => props.isCentered ? 'center' : 'flex-start'};
  background-image: url(${props => !props.isBackgroundVideo ? props.background : null});
  background-size: cover;
  min-height: ${props => (props.fullHeight && !props.compactHeader) ? '100vh' : props.compactHeader ? '200px' : '856px'};
  position: relative;
  z-index: 1;

  ${({ centeredBackground }) => centeredBackground && `
    background-position: center;
  `}

  @media only screen and (max-width: 1200px){
    min-height: auto;
  }
`;

const Underlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    video {
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
    }
`;

Header.propTypes = {
  background: PropTypes.string,
  isBackgroundVideo: PropTypes.bool,
  isCentered: PropTypes.bool,
};

export default Header;
