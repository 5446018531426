import React, { Component } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import DottedLine from '../../assets/images/dotted-line.png'

class LightIconsColumns extends Component {
  render() {
    const { data } = this.props;
    return (
      <Wrapper>
        { data && data.map(column =>
          <Column key={column.id}>
            <LazyLoad>
              <Icon src={column.icon} />
              { column.id < 3 ? <DottedLines/> : null }
            </LazyLoad>
            <Heading>{column.heading}</Heading>
            <Body>{column.body}</Body>
          </Column>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:  space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;

  @media only screen and (max-width: 900px){
    flex-direction: column;
  }
`;

const DottedLines = styled.div`
    content: '';
    width: 220px;
    height: 3px;
    background: url(${DottedLine});
    background-size: contain;
    position: absolute;
    right: -42%;
    top: 15%;

    @media only screen and (max-width: 1220px){
      display: none;
    }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items:  center;
  width: calc(100% / 3 - 50px);
  margin: 0 25px;
  position: relative;

  @media only screen and (max-width: 1200px){
    margin: 0 auto;
  }
  @media only screen and (max-width: 900px){
    width: 100%;
  }
`;

const Icon = styled.img`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.h3`
  margin-top: 30px;
  text-align: center;
  margin-bottom: 20px;

  @media only screen and (max-width: 1200px){
    font-size: 20px;
    margin: 0 auto 20px;
    line-height: normal;
  }
`;

const Body = styled.p`
  text-align: center;

  @media only screen and (max-width: 1200px){
    font-size: 16px;
  }
`;

export default LightIconsColumns;
