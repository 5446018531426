import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import {
  PATH_HOMEPAGE,
  PATH_PRODUCT,
  PATH_ABOUT,
  PATH_CONTACT,
  PATH_TOS,
  PATH_DEMO,
  PATH_SERVICES,
  PATH_ARTICLE,
  PATH_STUDY,
  PATH_SOURCES,
  PATH_PAGE
} from './routes/routePaths';

import Homepage from './containers/Homepage/index';
import AboutUs from './containers/AboutUs/index';
import Services from './containers/Services/index';
import Product from './containers/Product/index';
import Contact from './containers/Contact/index';
import Demo from './containers/Demo/index';
import TOS from './containers/TOS/index';
import NotFound from './containers/NotFound/index';
import Article from './containers/Article/index';
import Page from './containers/Page/index';
import CaseStudy from './containers/CaseStudy/index';
import Sources from './containers/Sources/index';

import ScrollToTop from './components/ScrollToTop/index';

export default function App() {
  return (
    <Router>
       <ScrollToTop />
        <Switch>
          <Route path={PATH_HOMEPAGE} exact component={Homepage}/>
          <Route path={PATH_PRODUCT} component={Product}/>
          {/* 
            <Route path={PATH_ABOUT} component={AboutUs}/> // requested to hide the page
          */}
          {/*  
          <Route path={PATH_SOURCES} component={Sources}/>
          */} 
          <Route path={PATH_SERVICES} component={Services}/>
          <Route path={PATH_CONTACT} component={Contact}/>
          <Route path={PATH_DEMO} component={Demo}/>
          <Route path={PATH_TOS} component={TOS}/>
          <Route path={`${PATH_ARTICLE}/*/*`} component={Article}/>
          <Route path={`${PATH_STUDY}/*/*`} component={CaseStudy}/>
          <Route path={`${PATH_PAGE}/*/*`} component={Page}/>
          <Route component={NotFound}/>
        </Switch>
    </Router>
  );
}
