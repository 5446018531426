import React from 'react';
import styled from 'styled-components';


const WhiteSection = ({ className, transparentBg, paddingBottom, children }) => {
  return (
    <Wrapper className={className} isTransparent={transparentBg} paddingBottom={paddingBottom}>{children}</Wrapper>
  );
}

const Wrapper = styled.section`
  background: ${props => props.isTransparent ? 'transparent' : '#FFFFFF'};
  position: relative;
  min-height: 300px;
  padding-bottom: ${props => props.paddingBottom};

  overflow: ${props => props.className && props.className === 'overflow-h' ? 'hidden' : 'initial'};
`;

export default WhiteSection;
