import React, { Component } from 'react';
import styled from 'styled-components';
import Layout from '../Layout';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import Background from './background.jpg';
import GraySection from '../../components/GraySection';
import WhiteSection from '../../components/WhiteSection';
import CenteredSection from '../../components/CenteredSection/index';
import LazyLoad from 'react-lazyload';
import Policeman from '../../assets/images/policeman.png';
import Thief from '../../assets/images/thief.png';
import ServicesLaptop from '../../assets/images/servicesComp.png';
import LightningIcon from '../../assets/images/lightningIcon.png';
import CheckIcon from '../../assets/images/checkIcon.png';
import HomepageIconsSet from '../../components/HomepageIconsSet';
import BlueGradientSection from '../../components/BlueGradientSection';
import LeftSideSection from '../../components/LeftSideSection';
import { Link } from 'react-router-dom';
import { PATH_DEMO } from '../../routes/routePaths';
import BG from '../../components/LeftSideSection/background.png';
import {default as CardWrapp} from '../../components/Card';
import { Cards } from './data';
import Footer from '../../components/Footer';
import TryDemoSection from '../../components/TryDemoSection';
import { getBlocksByOverFolderId } from '../../functions/blocks';
import Loading from '../../components/Loading';

import Atom from '../../components/HomepageIconsSet/img/atom.png';
import Drink from '../../components/HomepageIconsSet/img/drink.png';
import Piechart from '../../components/HomepageIconsSet/img/piechart.png';
import ShieldRounded from '../../components/HomepageIconsSet/img/shieldRounded.png';
import Statistics from '../../components/HomepageIconsSet/img/statistics.png';
import Tower from '../../components/HomepageIconsSet/img/tower.png';
import SocialImage from '../../assets/images/social.png';
import Helmet from 'react-helmet';
import { MainTitle } from '../../constants/global';

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {blocks: undefined};
  }

  componentDidMount = () => {
    getBlocksByOverFolderId(3).then(blocks => {
      this.setState({blocks: blocks.data.data});
      console.log(blocks);
    });
  }

  render() {

    const { location } = this.props;
    const { blocks } = this.state;
    const heading = blocks ? blocks.folders[0].blocks[0].content : null;
    const subheading = blocks ? blocks.folders[0].blocks[1].content : null;

   const columns5 = blocks ? [
      {
        id: 1,
        icon: Piechart,
        body: blocks ? blocks.folders[5].blocks[1].content : null
      },
      {
        id: 2,
        icon: Drink,
        body: blocks ? blocks.folders[5].blocks[2].content : null
      },
      {
        id: 3,
        icon: Statistics,
        body: blocks ? blocks.folders[5].blocks[3].content : null
      },
      {
        id: 4,
        icon: Tower,
        body: blocks ? blocks.folders[5].blocks[4].content : null
      },
      // Asked to be hidden
      // {
      //   id: 5,
      //   icon: ShieldRounded,
      //   body: blocks ? blocks.folders[5].blocks[5].content : null
      // },
      // {
      //   id: 6,
      //   icon: Piechart,
      //   body: blocks ? blocks.folders[5].blocks[6].content : null
      // }
    ] : [];

    return (
      <Layout location={location}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Služby | {MainTitle}</title>
          <meta property="og:image" content={SocialImage} />
        </Helmet>
        <Header background={Background} isBackgroundVideo={false} fullHeight={true}>
          <HeaderContent
            heading={heading}
            subheading={subheading}
            buttonLink={PATH_DEMO}
            buttonText={blocks ? blocks.folders[0].blocks[2].content : null}
            isCentered={false}
          />
        </Header>
        <GraySection>
          <CenteredSection transparent={true}>
            <h1>{blocks ? blocks.folders[1].blocks[0].content : <Loading/>}</h1>
            <TwoColumns>
              <Column>
                <LazyLoad>
                  <img src={Policeman} alt="" className="mt40 mb40"/>
                </LazyLoad>
                <h3 className="mb20">{blocks ? blocks.folders[1].blocks[1].name : null}</h3>
                <Text>
                  {blocks ? blocks.folders[1].blocks[1].content : null}
                </Text>
              </Column>
              <Column>
                <LazyLoad>
                  <img src={Thief} alt="" className="mt40 mb40"/>
                </LazyLoad>
                <h3 className="mb20">{blocks ? blocks.folders[1].blocks[2].name : null}</h3>
                <Text>
                  {blocks ? blocks.folders[1].blocks[1].content : null}
                </Text>
              </Column>
            </TwoColumns>
          </CenteredSection>
        </GraySection>
        <GraySection doublebackground={true}>
          <CenteredSection transparent={true}>
            <h2 className="mb50 mt70">
              {blocks ? blocks.folders[2].blocks[0].content : <Loading/>}
            </h2>
            <p>
              {blocks ? blocks.folders[2].blocks[1].content : null}
            </p>
            <FullImage src={ServicesLaptop} className="mt50" alt=""/>
            {blocks ?
            <TwoColumns spaceBetween={true} className="mb50">
              <Column paddingRight={true}>
                <Card>
                  <Heading>
                    <img src={LightningIcon} alt=""/>
                    <h3>{blocks.folders[3].blocks[0].content}</h3>
                  </Heading>
                  <Subheading>
                    {blocks.folders[3].blocks[1].content}
                  </Subheading>
                  <p>
                    {blocks.folders[3].blocks[2].content}
                  </p>
                  <Purple>
                    <div>
                      <span>COVERAGE:</span><span>{blocks.folders[3].blocks[3].content}</span>
                    </div>
                    <div>
                      <span>First response SLA:</span><span>{blocks.folders[3].blocks[4].content}</span>
                    </div>
                  </Purple>
                </Card>
              </Column>
              <Column>
                <Card>
                  <Heading>
                    <img src={CheckIcon} alt=""/>
                    <h3>{blocks.folders[4].blocks[0].content}</h3>
                  </Heading>
                  <Subheading>
                    {blocks.folders[4].blocks[1].content}
                  </Subheading>
                  <p>
                    {blocks.folders[4].blocks[2].content}
                  </p>
                  <Purple>
                    <div>
                      <span>COVERAGE:</span><span>{blocks.folders[4].blocks[3].content}</span>
                    </div>
                    <div>
                      <span>First response SLA:</span><span>{blocks.folders[4].blocks[4].content}</span>
                    </div>
                  </Purple>
                </Card>
              </Column>
            </TwoColumns>
            : <Loading/>
            }
          </CenteredSection>
        </GraySection>
        <BlueGradientSection>
          <HomepageIconsSet columns={columns5} horizontal={true} header={blocks ? blocks.folders[5].blocks[0].content : null}/>
        </BlueGradientSection>
        <WhiteSection className="overflow-h">
          <CenteredSection>
            <LeftSideSection className="mb70">
              <h2>
                { blocks ? blocks.folders[6].blocks[0].content : <Loading/> }
              </h2>
              <p>
                { blocks ? blocks.folders[6].blocks[1].content : null }
              </p>
              <Link to={`${PATH_DEMO}`} className="purpleButton">{ blocks ? blocks.folders[6].blocks[2].content : null }</Link>
              <BackgroundImage src={BG} alt=""/>
            </LeftSideSection>
          </CenteredSection>
        </WhiteSection>
        <GraySection>
          <CenteredSection transparent>
            <h2>{ blocks ? blocks.folders[7].blocks[0].content : <Loading/> }</h2>
            <WrapperForCards>
              { Cards && Cards.map((card, index) => <CardWrapp image={card.image} title={ blocks ? blocks.folders[7].blocks[index+1].name : null } content={ blocks ? blocks.folders[7].blocks[index+1].content : null } key={card.id} id={card.id}/>)}
            </WrapperForCards>
          </CenteredSection>
        </GraySection>
        <Footer>
          <TryDemoSection allowTick={true} />
        </Footer>
      </Layout>
    );
  }
}

const TwoColumns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.spaceBetween ? 'space-between' : `center`};
  width: 100%;

  @media only screen and (max-width: 800px){
    flex-direction: column;
  }
`;

const FullImage = styled.img`
  width: 100%;
`;

const BackgroundImage = styled.img`
  position: absolute;
  right: -140px;
`;

const WrapperForCards = styled.div`
  display: flex;
  margin-top: 70px;

  @media only screen and (max-width: 960px){
    flex-direction: column;
  }
`;

const Purple = styled.div`
  position: relative;
  margin-top: 25px;
  text-align: left;
  margin-top: 25px;
  padding-left: 20px;

  span:first-child {
    opacity: 0.3;
    color: #2d175b;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.5px;
    line-height: 30px;
    text-transform: uppercase;
    font-family: 'Gilroy-ExtraBold';
    margin-right: 10px;
  }

  span:first-child {
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    font-family: 'Gilroy-ExtraBold';
    margin-right: 10px;
  }

  &:after {
    content: '';
    border-radius: 1px;
    background-color: #b095d2;
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 15px;
  }
`;

const Subheading = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  font-family: "Gilroy-Bold";
  opacity: 1;
  color: #000;
  text-align: left;
  margin-top: 20px;
`;

const Card = styled.div`
  background: #FFF;
  box-shadow: 0 20px 36px rgba(37, 17, 48, 0.08);
  border-radius: 6px;
  min-height: 10px;
  width: calc(100% - 80px);
  margin-top: 60px;
  padding: 30px 40px;

  p {
    text-align: left;
    margin-top: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100%/2);
  padding-right: ${props => props.paddingRight ? '20px' : `0`};

  @media only screen and (max-width: 800px){
    width: 100%;
    padding-right: 0;
  }
`;

const Text = styled.p`
  max-width: 80%;
`;

export default Services;
