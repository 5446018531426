import React, { Component } from 'react';
import styled from 'styled-components';
import { ButtonLink } from '../../styles/variables';
import { PATH_DEMO } from '../../routes/routePaths';
import IconTick from '../../assets/images/icon-tick.png';
import LazyLoad from 'react-lazyload';

import { getBlocksByFolderId } from '../../functions/blocks';
import Loading from '../../components/Loading';

class TryDemoSection extends Component {

  constructor(props) {
    super(props);
    this.state = {sectionData: undefined};
  }

  componentDidMount = () => {
    getBlocksByFolderId(26).then(sectionData => {
      this.setState({sectionData: sectionData.data.data});
    });
  }

  render() {
    const { allowTick } = this.props;
    const { sectionData } = this.state;
    let ticks = null;

    if(allowTick){
      ticks = (
        <RowOfTicks>
        <OneTick>
          <LazyLoad>
            <img src={IconTick} alt=""/>
            <span>{sectionData ? sectionData.blocks[1].content : null}</span>
          </LazyLoad>
        </OneTick>
        <OneTick>
          <LazyLoad>
            <img src={IconTick} alt="" />
            <span>{sectionData ? sectionData.blocks[2].content : null}</span>
          </LazyLoad>
        </OneTick>
        <OneTick>
          <LazyLoad>
            <img src={IconTick} alt="" />
            <span>{sectionData ? sectionData.blocks[3].content : null}</span>
          </LazyLoad>
        </OneTick>
      </RowOfTicks>
      );
    }

    return (
      <TryDemoWrapper>
        <h1>{sectionData ? sectionData.blocks[0].content : <Loading />}</h1>
        {ticks}
        <ButtonLink to={PATH_DEMO}>{sectionData ? sectionData.blocks[4].content : null}</ButtonLink>
      </TryDemoWrapper>
    );
  }
}

const TryDemoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 110px 0;
  text-align: center;
  align-items: center;

    h1 {
      color: #FFF;
      max-width: 60%;
      margin-bottom: 70px;
    }

    @media only screen and (max-width: 900px){
      margin: 55px 0;
    }
    @media only screen and (max-width: 800px){
      h1{
        max-width: 95%;
      }
    }
`;

const RowOfTicks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 90px;
  @media only screen and (max-width: 1024px){
    width: 90%;
    flex-direction: column;
    margin: 0 auto 35px;
  }
`;

const OneTick = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img {
    margin-right: 10px;
  }

  span {
    opacity: 0.7;
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    @media only screen and (max-width: 1024px){
      font-size: 18px;
    }
    @media only screen and (max-width: 768px){
      font-size: 16px;
    }
  }

`;

export default TryDemoSection;
