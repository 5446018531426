import React from 'react';
import styled from 'styled-components';

import { globalRadius, buttonBgColor } from '../../styles/variables';

const AuthorBox = ({name, position, avatar, reference}) => {
  return (
    <Author>
        <div className="info">
            <h2>{name}</h2>
            <p className="motto">{position}</p>
        </div>
        <div className="image">
            <img src={avatar} alt="" />
        </div>
        <p className="feedback">“{reference}”</p>
    </Author>
  );
}

const Author = styled.div`
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    box-shadow: 0 20px 36px rgba(37, 17, 48, 0.08);
    border-radius: ${globalRadius};
    background-color: ${buttonBgColor};
    color: #FFF;
    box-sizing: border-box;
    padding: 35px;

    div{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        &.info{
            width: calc(100% - 120px);
            padding-right: 30px;
        }
        &.image{
            width: 120px;
            height: 120px;
            border-radius: 50%;
            img{
                margin: 0;
                max-width: 100%;
            }
        }
    }

    h2{
        color: inherit!important;
        font-size: 26px!important;
        margin-bottom: 25px;
    }
    p{
        color: inherit;
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        &.motto{
            opacity: 0.7;
            margin-bottom: 25px;
        }
        &.feedback{
            font-weight: 500;
            font-style: italic;
            line-height: 30px;
            margin-bottom: 0;
        }
    }
`;

export default AuthorBox;
