import React, { useState, useEffect } from 'react';
import Logo from '../Logo/index';
import ContactHeader from '../ContactHeader/index';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import {
  PATH_HOMEPAGE,
  PATH_PRODUCT,
  PATH_SERVICES,
  PATH_SOURCES,
  // PATH_ABOUT,
  PATH_DEMO,
  PATH_CONTACT,

} from '../../routes/routePaths';
import Hamburger from 'react-hamburgers';

import { MenuLink, Container, scrolledMenuColor, smoothTransition } from '../../styles/variables';

const Navigation = () => {
  const [ visibleHamburger, setVisibleHamburger ] = useState(false);
  const [fixedMenu, setFixedMenu ] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setFixedMenu(window.scrollY > 0);
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    }
  });


  function handleActiveMenuItem(activeLink) {
    return activeLink === location.pathname ? 'active' : '';
  }

  return(
    <HeaderContainer className={fixedMenu ? 'fixedMenu' : null}>
      <ContactHeader handleActiveMenuItem={handleActiveMenuItem} />
      <div className="navigation">
        <MainNav>
          <Logo path={PATH_HOMEPAGE} />
          <Nav className={visibleHamburger ? 'toggled' : null }>
            <StyledLink to={PATH_HOMEPAGE} className={handleActiveMenuItem(PATH_HOMEPAGE)}>Úvod</StyledLink>
            <StyledLink to={PATH_PRODUCT} className={handleActiveMenuItem(PATH_PRODUCT)}>Produkt</StyledLink>
            <StyledLink to={PATH_SERVICES} className={handleActiveMenuItem(PATH_SERVICES)}>Služby</StyledLink>
            {/* <StyledLink to={PATH_SOURCES} className={handleActiveMenuItem(PATH_SOURCES)}>Zdroje</StyledLink> */}
            {/* <StyledLink to={PATH_ABOUT} className={handleActiveMenuItem(PATH_ABOUT)}>O nás</StyledLink> */}
            <StyledLink to={PATH_CONTACT} className={[handleActiveMenuItem(PATH_CONTACT), 'onlyMobile']}>Kontakt</StyledLink>
            <StyledLink to={PATH_DEMO} className={[handleActiveMenuItem(PATH_DEMO), 'menu-button']}>Demo zdarma</StyledLink>
          </Nav>
          <Hamburger active={visibleHamburger} type="spring" onClick={() => setVisibleHamburger(!visibleHamburger)}></Hamburger>
        </MainNav>
      </div>
    </HeaderContainer>
  );
}

const Nav = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 900px){
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    transition: ${smoothTransition};
    opacity: 0;
    visibility: hidden;
    &.toggled{
      width: 70%;
      background-color: #040831;
      border-right: 4px solid #FFF;
      overflow: auto;
      opacity: 1;
      visibility: visible;
    }
  }
`;

const MainNav = styled(Container)`
    display: flex;
    flex-direction: row;
    min-height: 60px;
    align-items: center;
    justify-content: space-between;

    .hamburger{
      display: none;
      @media only screen and (max-width: 900px){
        display: inline-block;
      }
    }
`;

const StyledLink = styled(Link)`
  ${MenuLink}
  font-size: 16px;

  &.menu-button {
    border-radius: 6px;
    border: 2px solid #ffffff;
    opacity: 0.5;
  }
  &.onlyMobile{
    display: none;
    margin-bottom: 25px;
    @media only screen and (max-width: 900px){
        display: block;
      }
  }
`;

const HeaderContainer = styled.div`
  position:  fixed;
  top: 0;
  &.fixedMenu {
    top: -50px;
    background-color: ${scrolledMenuColor};
    z-index: 99;
  }
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 4;
  transition: background-color .3s,top .3s;
  z-index: 50;
`;


export default Navigation;
