import CardImage1 from './cardImage1.png';
import CardImage2 from './cardImage2.png';
import CardImage3 from './cardImage3.png';

export const Cards = [
  {
    id: 1,
    image: CardImage1,
    heading: 'Accelerated time to protection',
    body: 'SentinelOne Vigilance adds an extra layer of protection to your SentinelOne solution. It augments your team with SentinelOne Cyber Security Analysts, who work with you to accelerate the detection, prioritization, and response to threats.'
  },
  {
    id: 2,
    image: CardImage2,
    heading: 'Forensics and threat hunting',
    body: 'Taking advantage of the power of the SentinelOne Endpoint Protection Platform, Cyber Security Analysts deliver on-demand sample forensics, Post detection hunting in your environment (requires SentinelOne Complete license), and detect security incidents.'
  },
  {
    id: 3,
    image: CardImage3,
    heading: 'Augment your security team',
    body: 'SentinelOne Cyber Security Analysts run through suspicious events in your SentinelOne console, conducting sample analysis as needed. We augment your security team by determining if events are threats or benign. You receive proactive notifications to keep you abreast of any critical events.'
  }
];
