import React from 'react';
import LoadingGif from './loading.svg';

const Loading = () => {
  return (
    <img src={LoadingGif} alt="Loading"/>
  );
};

export default Loading;
