import styled from 'styled-components';
import { Link } from 'react-router-dom';

/* Colors */
export const mainAccent = 'blue';
export const defaultTextColor = '#1a2a40';
export const opacityTextColor = 'rgba(26, 42, 64, 0.7)';
export const navbarLinksColor = '#FFFFFF';
export const scrolledMenuColor = 'rgba(0,0,0,.85)';
export const alertColor = '#da5757';
export const inputBorderColor = '#b4b8c2';
export const inputTextColor = '#747c87';
export const buttonBgColor = '#7b61d6';
export const buttonBgColorHover = '#000528';
export const defaultLinkColor = '#7e59ff';
export const blockQuoteColor = '#5d40c4';

/* Variables */
export const globalRadius = '6px';
export const globalNegativeMargin = '-150px';
export const globalBottomSectionMargin = '135px';
export const inputRadius = '4px';
export const smoothTransition = 'ease-in-out 0.5s';

/* Layout */
export const containerSize = '1110px';


/* Global components */

export const Container = styled.div`
    max-width: ${containerSize};
    margin: 0 auto;

    @media (max-width: ${containerSize}) {
      margin: 0 1rem;
    }
`;

export const MenuLink = `
  margin: 0 5px;
  color: ${navbarLinksColor};
  opacity: 0.6;
  transition: 300ms all;
  font-weight: 400;
  padding: 12px 20px;

  &:hover, &.active {
    opacity: 1 !important;
  }
`;

export const ButtonLink = styled(Link)`
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: ${globalRadius};
  border: 2px solid #a389fe;
  background-color: #33355f;
  padding: 20px 45px;
  color: #FFF;
  transition: ${smoothTransition};
  
  &:hover{
    background-color: #090e3d;
    border-color: #25165d;
  }
`;
