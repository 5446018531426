import React from 'react';
import styled from 'styled-components';
import Doublebackground from '../../assets/images/doublebackground.png';


const GraySection = ({ customStyle, children, doublebackground = false }) => {
  return (
    <Wrapper doublebackground={doublebackground} style={customStyle}>{children}</Wrapper>
  );
}

const Wrapper = styled.section`
  background: ${props => !props.doublebackground ? '#f8fafc' : `url(${Doublebackground})`};
  background-size: contain;
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
`;

export default GraySection;
