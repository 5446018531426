import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MenuLink, Container } from '../../styles/variables';
import { getBlocksByFolderId } from '../../functions/blocks';

import {
  PATH_CONTACT,
} from '../../routes/routePaths';


class ContactHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocks: undefined,
      openIntercom: false
    };
  }

  componentDidMount = () => {
    getBlocksByFolderId(49).then(blocks => {
      this.setState({blocks: blocks.data.data});
    });
  }

  handleOpenIntercom = () => {
    const {openIntercom} = this.state;
    this.setState({openIntercom: !openIntercom})
    window.Intercom(openIntercom ? 'hide' : 'show');
  }

  render() {
    const { handleActiveMenuItem } = this.props;
    const { blocks } = this.state;

    return (
      <TopBar className="top-bar">
        <StyledContactHeader>
            <StyledLink className="phone" to="">{blocks ? blocks.blocks[0].content : null}</StyledLink>
            <StyledDiv id="intercomToggle" onClick={this.handleOpenIntercom }>Podpora</StyledDiv>
            <StyledLink to={PATH_CONTACT} className={handleActiveMenuItem(PATH_CONTACT)}>Kontakt</StyledLink>
        </StyledContactHeader>
      </TopBar>
    );
  }

}

const StyledContactHeader = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
`;

const TopBar = styled.div`
    height: 50px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
`;

const StyledLink = styled(Link)`
  ${MenuLink}
  font-size: 14px;
  padding: 12px 10px;

  &:hover, .active {
    opacity: 1;
  }

  &.phone{
    font-family: 'Gilroy-Bold';
  }

  @media only screen and (max-width: 500px){
    margin: 0;
  }
`;

const StyledDiv = styled.div`
  ${MenuLink}
  font-size: 14px;
  padding: 12px 10px;
  cursor: pointer;

  &:hover, .active {
    opacity: 1;
  }

  &.phone{
    font-family: 'Gilroy-Bold';
  }

  @media only screen and (max-width: 500px){
    margin: 0;
  }
`;



export default ContactHeader;
