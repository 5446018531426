import React, { Component } from 'react';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import WhiteSection from '../../components/WhiteSection/index';
import Background from '../../assets/images/textPageBg.png';
import { Container } from '../../styles/variables';
import styled from 'styled-components';

class TextPage extends Component {
  render() {
    const { children, heading } = this.props;
    return (
      <span>
        <Header background={Background} isBackgroundVideo={false} fullHeight={false} compactHeader>
          <HeaderContent
            heading={heading}
            isCentered={true}
            compactHeader
          />
        </Header>
        <WhiteSection>
          <Container>
            <Wrapper>
              {children}
            </Wrapper>
          </Container>
        </WhiteSection>
      </span>
    )
  }
}

const Wrapper = styled.div`
  padding: 70px 0;

  h3 {
    font-family: "Gilroy-ExtraBold";
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    text-transform: uppercase;
  }

  h4 {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin: 40px 0;
  }

  p {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    color: #000000;
    margin-bottom: 40px;
  }

  p ~ h4 {
    margin-top: 0;
  }
`;

export default TextPage;
