import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import './style/styles.css';
import styled from 'styled-components';
import { Container } from '../../styles/variables';
import NavigationRight from '../../assets/images/navigationRight.png';
import NavigationLeft from '../../assets/images/navigationLeft.png';
import { getAllFeedbacks } from '../../functions/feedbacks';
import Loading from '../../components/Loading';
import ReferenceSlide from './ReferenceSlide';

class ReferenceSection extends Component {
  constructor(props) {
    super(props);
    this.state = {feedbacks: undefined};
  }

  componentDidMount = () => {
    getAllFeedbacks(1).then(feedbacks => {
      this.setState({feedbacks: feedbacks.data.data});
    });
  }


  render() {
    const { feedbacks } = this.state;
    const { heading } = this.props;
    console.log(heading);

    const options = {
      items: 1,
      nav: true,
      rewind: true,
      autoplay: false,
      mouseDrag: false,
      animateOut: 'fadeOut',
      navText: [`<img src='${NavigationLeft}'>`,`<img src='${NavigationRight}'>`]
  };

    return (
      <Wrapper>
        <Container>
          <Column>
            <h2>{heading}</h2>
            {feedbacks ?
            <OwlCarousel ref="car" options={options}>
              {feedbacks.map((feedback, index) => {
                return <ReferenceSlide
                 name={`${feedback.first_name} ${feedback.last_name}`}
                 position={feedback.rank}
                 text={feedback.text}
                 image={feedback.avatar}
                 index={index}
                 all={feedbacks.length}
                />
              })}
            </OwlCarousel> :
            <Loading/>
            }
          </Column>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  min-height: 400px;
  background-color: #FFF;

  .owl-stage-outer {
    z-index: 1;
  }

  .owl-nav {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 965px) {
      justify-content: flex-end;
      margin-top: 20px;
    }

    @media screen and (max-width: 650px) {
      justify-content: center;
    }

    margin-top: -60px;
    z-index: 999;
    position: relative;

    & > div {
      cursor: pointer;
    }

    .owl-prev {
      margin-right: 20px;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 100px 0 150px 0;

  h2 {
    margin-bottom: 80px;

    @media screen and (max-width: 650px) {
      margin-bottom: 20px;
    }
  }
`;

export default ReferenceSection;
