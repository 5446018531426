import React, { Component } from 'react';
import Layout from '../Layout';
import TextPage from '../TextPage';
import Footer from '../../components/Footer';
import SocialImage from '../../assets/images/social.png';
import Helmet from 'react-helmet';
import { MainTitle } from '../../constants/global';
import { getPostById } from '../../functions/posts';
import Loading from '../../components/Loading';

class TOS extends Component {
  constructor(props) {
    super(props);
    this.state = {post: undefined};
  }

  componentDidMount = () => {
    getPostById(6).then(post => {
      this.setState({post: post.data.data});
    });
  }

  render() {
    const { location } = this.props;
    const { post } = this.state;

    const heading = post && post.title

    return (
      <Layout location={location}>
         <Helmet>
          <meta charSet="utf-8" />
          <title>{post ? post.title : 'Blog article'} | {MainTitle}</title>
          <meta name="description" content={post ? post.meta_description : 'Blog description'} />
          <meta property="og:image" content={SocialImage} />
        </Helmet>
        <TextPage heading={heading}>
          { post ? <div dangerouslySetInnerHTML={{__html: post.body}}/> : <Loading/>}
        </TextPage>
        <Footer hideAbout={true} />
      </Layout>
    );
  }
};

export default TOS;
