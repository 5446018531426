import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    PATH_TOS,
    PATH_CONTACT,
  } from '../../routes/routePaths';

import { Container } from '../../styles/variables';

import footerPattern     from '../../assets/images/footerPattern.png'
import column_sentinel     from '../../assets/images/sentinel@3x.png'
import column_appsec     from '../../assets/images/logo.svg'
//import column_apptc     from '../../assets/images/apptc@3x.png'
import instagram     from '../../assets/images/icon-instagram.svg'
import facebook     from '../../assets/images/icon-facebook.svg'
import twitter     from '../../assets/images/icon-twitter.svg'

import { getBlocksByOverFolderId } from '../../functions/blocks';
import Loading from '../../components/Loading';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {footer: undefined};
    }

    componentDidMount = () => {
        getBlocksByOverFolderId(5).then(footer => {
            this.setState({footer: footer.data.data});
        });
    }

  render() {
    const { children, hideAbout } = this.props;
    const { footer } = this.state;

    return (
        <Wrapper>
            { children }
            <InnerWrap>
                <StyledWrap>
                    {!hideAbout ? <h3>{footer ? footer.folders[0].blocks[0].content : <Loading />}</h3> : null}
                    <Column>
                        <img className="logo" src={column_sentinel} alt="" />
                        <p>{footer ? footer.folders[1].blocks[0].content : null}</p>
                        <a className="link" href="/">Sone.cz</a>
                    </Column>
                    <Column>
                        <img className="logo" src={column_appsec} alt="" />
                        <p>{footer ? footer.folders[1].blocks[1].content : null}</p>
                        <a className="link" href="http://www.appsec.cz" target="_blank" rel="noopener noreferrer">Appsec.cz</a>
                    </Column>
                    {/* <Column>
                        <span className="logo"></span>
                        <p>{footer ? footer.folders[1].blocks[2].content : null}</p>
                        <span className="link">Připravujeme</span>
                    </Column> */}
                    <Links>
                        <div>
                            <Link to={PATH_CONTACT}>Kontakt</Link>
                            <Link to={PATH_TOS} className="anchorBlock">Zásady ochrany osobních údajů</Link>
                        </div>
                        <div className="social">
                            <a href="/"><img src={instagram} alt="" /></a>
                            <a href="/"><img src={facebook} alt="" /></a>
                            <a href="/"><img src={twitter} alt="" /></a>
                        </div>
                    </Links>
                    <p className="copyright">{footer ? footer.folders[0].blocks[1].content : null}</p>
                </StyledWrap>
            </InnerWrap>
        </Wrapper>
    );
  }

}

const Wrapper = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        max-width: 635px;
        display: inline-block;
        top: 0;
        right: 0;
        background-image: url(${footerPattern});
        background-repeat: no-repeat;
        background-size: cover;
    }

    & > *{
        z-index: 2;
    }

    @media only screen and (max-width: 1200px){
        &:after{
            display: none;
        }
    }
`;

const InnerWrap = styled.div`
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 48px 0;
`;

const StyledWrap = styled(Container)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h3{
        width: 70%;
        color: #ffffff;
        opacity: 0.8;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
    }

    .copyright{
        color: rgba(255,255,255,0.45);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }

    @media only screen and (max-width: 1200px){
        h3{
            width: 100%;
        }
    }
`;

const Column = styled.div`
    width: 33%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding-right: 30px;
    margin: 48px 0;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }

    .logo{
        &:not(img){
            width: 100%;
        }
        height: 30px;
        margin-bottom: 24px;
    }
    p{
        width: 100%;
        opacity: 0.6;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px){
          max-width: 60%;
        }
    }
    .link{
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
    }
    a.link{
        color: #6556ad;
        &:hover{
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: 1200px){
        width: 100%;
        padding: 0;
        margin: 40px auto 0;
        .logo{
            margin: 0 auto 12px;
        }
        p{
            margin-bottom: 12px;
            text-align: center;
        }
        .link{
            margin: 0 auto;
        }
    }
`;

const Links = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 30px 0;

    div{
        width: 50%;
        &.social{
            text-align: right;
            a{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(255,255,255,0.15);
                width: 36px;
                height: 36px;
                margin-left: 15px;
                margin-right: 0;
                border-radius: 50%;
                &:hover{
                    text-decoration: none;
                    background-color: #6556ad;
                }

            }
        }

        a{
            opacity: 0.4;
            color: #ffffff;
            font-size: 14px;
            font-weight: 600;
            text-decoration: none;
            margin-right: 40px;
            &:hover{
                text-decoration: underline;
            }
        }

        @media only screen and (max-width: 1200px){
            width: 100%;
            text-align: center;
            a{
                margin: 0 25px;
            }
            &.social{
                text-align: center;
                margin: 30px auto 0;
                a{
                    margin: 0 10px;
                }
            }
        }
    }
`;

export default Footer;
