import  React, { Component } from 'react';
import styled from 'styled-components';

class BlocksWithUnderlineHeading extends Component {
  render() {
    const { blocks } = this.props;
    let columns = [];

    if(blocks) {
      let i;
      for (i = 1; i < 5; i++) {
        columns.push (
          <Column key={i}>
            <Heading>
              {blocks ? blocks.blocks[i].name : null}
            </Heading>
            <Description>
              {blocks ? blocks.blocks[i].content : null}
            </Description>
          </Column>
        )
      }
    }

    return (
      <Wrapper>
        { columns }
      </Wrapper>
    )
  }

}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  @media only screen and (max-width: 1200px){
    flex-wrap: wrap;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 4 - 40px);
  margin: 40px;
  text-align: left;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    h3 {
      opacity: 1;
      color: #7e59ff;

      &:before {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1200px){
    width: calc(100% / 2 - 40px);
    margin: 20px;

    &:nth-of-type(odd) {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 600px){
    width: calc(100%);
    margin: 20px 0 20px;

  }
`;

const Heading = styled.h3`
  position: relative;
  opacity: 0.4;
  color: #000000;
  transition: 500ms all;
  margin-bottom: 30px;

  &:after {
    content: '';
    height: 4px;
    width: 100%;
    position: absolute;
    background-color: #000000;
    opacity: 0.2;
    left: 0;
    bottom: -10px;
  }

  &:before {
    content: '';
    height: 4px;
    width: 0%;
    position: absolute;
    background-color: #7e59ff;
    left: 0;
    bottom: -10px;
    transition: 500ms all;
  }
`;

const Description = styled.p``;

export default BlocksWithUnderlineHeading;
