import React, { Component } from 'react';
import styled from 'styled-components';

class Card extends Component {
  render() {
    const { image = false, title, content, id } =  this.props;

    return (
      <Wrapper key={id}>
        { image && <img src={image} alt=""/> }
        <Content>
          <h4>{ title }</h4>
          <p>{ content }</p>
        </Content>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  width: calc(100% / 3);
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 16px 32px rgba(37, 17, 48, 0.06);
  border-radius: 6px;
  background-color: #ffffff;
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
  }

  @media only screen and (max-width: 960px){
    width: 100%;
    flex-direction: row;
    align-items: normal;
    margin-bottom: 45px;
  }

  @media only screen and (max-width: 800px){
    img{
      display: none;
    }
  }
`;

const Content = styled.div`
  padding: 25px 40px;
  text-align: left;

  h4 {
    margin-bottom: 15px;
  }

  @media only screen and (max-width: 800px){
    text-align: center;
  }
`;

export default Card;
