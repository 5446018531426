import React from 'react';
import styled from 'styled-components';
import useIntercom from "use-intercom-hook";
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';
import { PATH_TOS } from '../../routes/routePaths';

import Navigation from '../../components/Navigation/index';

const Layout = ({ children }) => {
  const appSet = {
    custom_launcher_selector:'#intercomToggle'
  };

  function Chat() {
    useIntercom('glubkiec', {
      alignment: "right",
    });
    return <div />;
  }

  return (
    <div>
      <Navigation />
      <Main>{children}</Main>
      <CookieConsent
          location="bottom"
          buttonText="Souhlasím"
          cookieName="AppsecCookie"
          style={{ background: "#2B373B", zIndex: 9999999999 }}
          buttonStyle={{ background: "#A9396C", color: "#fff", fontSize: "16px" }}
          expires={150}
      >
          Používáním těchto stránek souhlasíte s používáním souborů <StyledLink to={PATH_TOS}>cookies a zpracováním osobních údajů</StyledLink>.
      </CookieConsent>
      <Chat {...appSet}/>
    </div>
  )
}

const Main = styled.div`

`;

const StyledLink = styled(Link)`
  color: #FFFF;
  text-decoration: underline;
`;

export default Layout;
