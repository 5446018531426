import React from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import SearchIcon from '../../assets/images/searchIcon.png';

const Search = ({ handleSearch }) => {

  const setSearchTerm = debounce(searchTerm => {
    handleSearch(searchTerm);
  }, 500)

  return (
    <Wrapper icon={SearchIcon}>
      <input type="search" name="search" onChange={e => {setSearchTerm(e.target.value)}} placeholder="Search ..." />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 54px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: #edeef0;
  min-width: 240px;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 900px){
    width: 100%;
    margin-bottom: 30px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;
    width: 30px;
    height: 100%;
    background: url(${props => props.icon});
    background-repeat: no-repeat;
    background-position: center;
  }
  input {
    -webkit-appearance: none;
    width: 100%;
    position:absolute;
    top:0px;
    height:100%;
    background: transparent;
    border: none;
    padding-left: 50px;
    padding-right: 15px;
    text-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    color: #818a96;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;

    ::-webkit-input-placeholder {
      text-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
      color: #818a96;
      font-family: Raleway;
      font-size: 16px;
      font-weight: 600;
    }

    :-ms-input-placeholder {
      text-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
      color: #818a96;
      font-family: Raleway;
      font-size: 16px;
      font-weight: 600;
    }

    ::placeholder {
      text-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
      color: #818a96;
      font-family: Raleway;
      font-size: 16px;
      font-weight: 600;
    }

    &:focus {
      outline: none;
    }
  }
`;

export default Search;
