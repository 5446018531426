import React from 'react';
import styled from 'styled-components';
import { Container, defaultLinkColor, opacityTextColor } from '../../styles/variables';
import { Link } from 'react-router-dom';
import LinkArrow from '../../assets/images/linkArrow.png';

const PagesRow = ({articles, withImages = false, columns = 3, disableButton = false, onlyTextColumns = false}) => {
  return (
    <Wrapper columns={columns} disableButton={disableButton}>
      {articles.slice(0, columns).map((article, index) => {
        return (
          <Article key={index} columns={columns} className={onlyTextColumns ? 'strict' : null}>
            { article.event_image && <Thumbnail src={article.event_image} />}
            <h3>{ article.event_title.slice(0, 80) + (article.event_title.length > 80 ? "..." : "") }</h3>
            { !disableButton ? <LinkBtn href={article.event_url}>Číst více</LinkBtn> : null }
          </Article>
        )
      })}
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: ${props => props.columns > 3 ? '100px 0' : 0};

  @media only screen and (max-width: 900px){
    flex-direction: column;
    padding: 40px 0 60px;
  }
`;

const Article = styled.div`
  display: flex;
  width: calc(100% / ${props => props.columns});
  margin: 0 25px;
  flex-direction: column;
  align-items: flex-start;

  &.strict{
    margin-bottom: 25px;
    h3{
      text-transform: uppercase;
      margin: 70px 0 25px;
      text-align: left;
    }
  }
  p, ul li, ol li{
    color: ${opacityTextColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 900px){
    width: 100%;
    text-align: center;
    align-items: center;
    margin: 20px auto 0;
  }
`;

const LinkBtn = styled.a`
  position: relative;
  color: ${defaultLinkColor};
  font-weight: bold;
  margin-top: 20px;

  &:after {
    content: '';
    background: url(${LinkArrow});
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-10px);
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    transition: 500ms all;
  }

  &:hover:after {
    right: -30px;
  }
`;

const Thumbnail = styled.img`
  height: 120px;
  object-fit: cover;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    object-fit: contain;
  }
`;

export default PagesRow;
