import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SquareIcon from '../../components/SquareIcon';
import LinkArrow from '../../assets/images/linkArrow.png';

class ResourceCard extends Component {
  render() {
    const { color, text, link, linkText, id, activeIndex, tagID, type } =  this.props;

    if(activeIndex) {
      if(activeIndex !== tagID) {
        return null;
      }
    }

    return (
      <Wrapper key={id}>
        <Content>
          <div>
            <SquareIcon color={color}/>
            <h4>{ text }</h4>
          </div>
          <div>
            { type && type === 'source' ?
            <StyledLinkA href={link} style={{fontWeight: 'bold', color: '#7b61d6'}} target="_blank" rel="noopener noreferrer">{linkText}</StyledLinkA> :
            <StyledLink to={link}>{linkText}</StyledLink>
            }
          </div>
        </Content>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  width: calc(100% / 2);
  @media screen and (max-width: 900px){
    width: 100% !important;
    margin-right: 0 !important;
  }
  min-height: 230px;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 12px 36px rgba(37, 17, 48, 0.08);
  border-radius: 6px;
  background-color: #ffffff;
  &:nth-of-type(odd) {
    margin-right: 25px;
    width: calc(100% / 2 - 30px);
  }
  margin-bottom: 20px;

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
  }
`;

const Content = styled.div`
  padding: 25px 40px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 190px;

  h4 {
    margin-bottom: 15px;
    line-height: 34px;
    font-size: 22px;
    max-width: 80%;
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  color: #7b61d6;
  font-family: "Gilroy-ExtraBold";
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;

  &:after {
    content: '';
    background: url(${LinkArrow});
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-11px);
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    transition: 500ms all;
  }

  &:hover:after {
    right: -30px;
  }
`;

const StyledLinkA = styled.a`
  position: relative;
  color: #7b61d6;
  font-family: "Gilroy-ExtraBold";
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;

  &:after {
    content: '';
    background: url(${LinkArrow});
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-11px);
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    transition: 500ms all;
  }

  &:hover:after {
    right: -30px;
  }
`;

export default ResourceCard;
