import React, { Component } from 'react';
import styled from 'styled-components';
import { Container } from '../../styles/variables';

import Loading from '../../components/Loading';

class ProductNumberCards extends Component {
  render() {
    const { texts } = this.props;
    
    return (
      <Wrapper>
        <h2>{texts ? texts.blocks[0].content : <Loading/>}</h2>
        <TwoHeadings>
          <h3>{texts ? texts.blocks[1].content : null}</h3>
          <h3>{texts ? texts.blocks[2].content : null}</h3>
        </TwoHeadings>

        <BoxesRow>
          <PlainBox>
            <span>{texts ? texts.blocks[3].content : null}</span>
          </PlainBox>
          <PurpleBox>
            <span>{texts ? texts.blocks[4].content : null}</span>
            <Number>1</Number>
          </PurpleBox>
        </BoxesRow>

        <BoxesRow>
          <PlainBox>
            <span>{texts ? texts.blocks[5].content : null}</span>
          </PlainBox>
          <PurpleBox>
            <span>{texts ? texts.blocks[6].content : null}</span>
            <Number>2</Number>
          </PurpleBox>
        </BoxesRow>

        <BoxesRow>
          <PlainBox>
            <span>{texts ? texts.blocks[7].content : null}</span>
          </PlainBox>
          <PurpleBox>
            <span>{texts ? texts.blocks[8].content : null}</span>
            <Number>3</Number>
          </PurpleBox>
        </BoxesRow>
      </Wrapper>
    );
  }
}

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;

  h2 {
    margin-bottom: 60px;
  }
`;

const TwoHeadings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;

  @media only screen and (max-width: 1220px){
    display: none;
  }
`;

const BoxesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;

  @media only screen and (max-width: 800px){
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const PlainBox = styled.div`
  border-radius: 8px;
  border: 4px solid #eceef1;
  padding: 40px 150px 40px 40px;

  span {
    font-family: 'Gilroy-SemiBold';
    opacity: 0.55;
    color: #1a2a40;
    font-size: 21px;
    font-weight: 600;
    line-height: 32px;
    display: block;
    max-width: 70%;
  }

  @media only screen and (max-width: 800px){
    width: 85%;
    margin: 0 auto;
    padding: 40px 40px 100px 40px;
    text-align: center;
    box-sizing: border-box;

    span{
      max-width: none;
    }
  }
`;

const PurpleBox = styled.div`
  position: relative;
  box-shadow: 0 28px 48px rgba(37, 17, 48, 0.2), 0 4px 16px rgba(30, 14, 45, 0.35);
  border-radius: 8px;
  background-color: #6b46ec;
  padding: 70px;
  margin-left: -180px;

  span {
    color: #FFF;
    font-family: 'Gilroy-SemiBold';
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    display: block;
    max-width: 80%;
  }

  @media only screen and (max-width: 800px){
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    box-sizing: border-box;
    margin-top: -80px;

    span{
      max-width: none;
    }
  }
`;

const Number = styled.div`
  color: #7653f0;
  font-family: "Gilroy-ExtraBold";
  font-size: 240px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  right: 10px;
  bottom: -5%;

  @media only screen and (max-width: 800px){
    display: none;
  }
`;

export default ProductNumberCards;
