import React, { Component } from 'react';
import styled from 'styled-components';
import Layout from '../Layout/index';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import WhiteSection from '../../components/WhiteSection/index';
import CenteredSection from '../../components/CenteredSection/index';
import BackgroundImage from '../../assets/images/Sources@1x.png'
import Search from '../../components/Search';
import Labels from '../../components/Labels';
import ResourceCard from '../../components/ResourceCard';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import {getAllSources, getAllLabels} from '../../functions/sources';
import { CaseStudiesColor, ArticleColor } from '../../components/Labels/data';
import { PATH_ARTICLE, PATH_STUDY } from '../../routes/routePaths';
import SocialImage from '../../assets/images/social.png';
import Helmet from 'react-helmet';
import { MainTitle } from '../../constants/global';

class Sources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sources: undefined,
      labels: undefined,
      activeIndex: null,
      search: null
    };
  }

  handleActiveIndex = index => {
    this.setState({activeIndex: index})
  }

  handleDataShow = () => {
    const { search } = this.state;
    if(this.userHasValidSourceCache()) {
      const sources = JSON.parse(localStorage.getItem('sources'));
      const labels = JSON.parse(localStorage.getItem('labels'));
      this.setState({sources: sources, labels: labels});
    } else {
      getAllSources().then(sources => {

        let SourcesData = [];
        sources.data.posts.map(post => {
          return post.id !== 6 && SourcesData.push({
            type: 'post',
            id: post.id,
            title: post.title,
            updatedAt: post.updated_at,
            tag: 'Article',
            tagID: 999,
            color: ArticleColor,
            url: `${PATH_ARTICLE}/${post.id}/${post.slug}`,
            visibility: post.visibility === 'Visible to all',
            searched: search ? post.title.toLowerCase().includes(search.toLowerCase()) : true
          });
        });

        sources.data.trainings.map(post => {
          return  SourcesData.push({
            type: 'caseStudy',
            id: post.id,
            title: post.title,
            updatedAt: post.updated_at,
            tag: 'Case study',
            tagID: 998,
            color: CaseStudiesColor,
            url: `${PATH_STUDY}/${post.id}/${post.url_slug}`,
            visibility: post.visibility === 1,
            searched: search ? post.title.toLowerCase().includes(search.toLowerCase()) : true
          });
        });

        sources.data.videos.map(post => {
          return SourcesData.push({
            type: 'source',
            id: post.id,
            title: post.title,
            updatedAt: post.updated_at,
            tag: post.tags[0].label,
            tagID: post.tags[0].id,
            color: post.tags[0].color,
            url: post.attachments[0] ? post.attachments[0].attachment_url : null,
            visibility: 1,
            searched: search ? post.title.toLowerCase().includes(search.toLowerCase()) : true
          });
        });

        this.setState({sources: SourcesData});
        localStorage.setItem('sources', JSON.stringify(SourcesData));
      });
      getAllLabels().then(labels => {
        this.setState({labels: labels.data.data});
        localStorage.setItem('labels', JSON.stringify(labels.data.data));
      });
    }
  }

  componentDidMount = () => {
    this.handleDataShow();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevState.search !== this.state.search) {

      this.handleDataShow();
    }
  }

  userHasValidSourceCache = () => {

    // for now, i disabled caching
    if(localStorage.getItem('admin') || this.state.search) {
      console.log("not valid cache");
      return false;
    }

    const cacheTimestamp = localStorage.getItem('sourceCache') ? new Date(parseInt(localStorage.getItem('sourceCache'))) : null;

    if(!cacheTimestamp) {
      // user has noo cached sources
      localStorage.setItem('sourceCache', +new Date());
      return false;
    }

    const Now = +new Date();
    const difference = (Now - cacheTimestamp)/1000/60;

    // cache are valid for 30m
    if(difference < 30) {
      return true;
    }

    // caches are invalid - older then 30m
    this.removeCache();
    return false;

  }

  removeCache = () => {
    localStorage.removeItem('sourceCache');
  }

  handleSearch = (search) => {
    if(search !== '') {
      this.setState({ search, sources: undefined });
    } else {
      this.setState({ search: null, sources: undefined });
      this.removeCache();
      this.handleDataShow();
    }
  }

  render() {
    const { location } = this.props;
    const { sources, labels, activeIndex } = this.state;
    const heading = `Zdroje`

    return (
      <Layout location={location}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Zdroje | {MainTitle}</title>
          <meta property="og:image" content={SocialImage} />
        </Helmet>
        <Header background={BackgroundImage} isBackgroundVideo={false} centeredBackground compactHeader>
          <HeaderContent
            compactHeader
            heading={heading}
            isCentered={true}
          />
        </Header>
        <WhiteSection>
          <CenteredSection>
            <Row>
              <Search handleSearch={this.handleSearch}/>
              <Labels labels={labels} handleActiveIndex={this.handleActiveIndex} activeIndex={activeIndex} />
            </Row>
            <HR/>
            <Row flexWrap>
              { sources ?
                sources.map((card, index) =>
                card.visibility && card.searched ?
                  <ResourceCard
                    key={index}
                    text={card.title}
                    link={card.url}
                    linkText={'Číst více'}
                    color={card.color}
                    activeIndex={activeIndex}
                    tagID={card.tagID}
                    type={card.type}
                  /> :
                  null
                )
                :
                <Loading/>
              }
            </Row>
          </CenteredSection>
        </WhiteSection>
        <Footer hideAbout={true} />
      </Layout>
    )
  }
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: ${props => props.flexWrap ? 'wrap' : ''};

  @media screen and (max-width: 900px){
    flex-direction: column;
    align-items: center;
  }
`;

const HR = styled.div`
  display: block;
  height: 1px;
  border-radius: 1px;
  background-color: #e3e4e6;
  width: 100%;
  margin: 40px 0;
`;

export default Sources;
