import React from 'react';
import styled from 'styled-components';

const ReferenceSlide = ({name, position, text, image, index, all}) => {
  return (
    <SlideWrapper>
      <ImageHolder>
        <SlideNumber>{index+1}/{all}</SlideNumber>
        <Credential>
          <span>{name}</span>
          <span>{position}</span>
        </Credential>
        <img src={image} alt=""/>
        <Overlay/>
      </ImageHolder>
      <TextHolder>
        <p>{text.substring(0, 400)} ...</p>
      </TextHolder>
    </SlideWrapper>
  );
};

const SlideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;
const SlideNumber = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  opacity: 0.65;
  color: #ffffff;
`

const Credential = styled.div`
  position: absolute;
  bottom: 25px;
  left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex;
  text-align: left;
  z-index: 2;

  span:nth-child(1) {
    color: #ffffff;
    font-family: "Gilroy-Bold";
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;
  }
  span:nth-child(2) {
    opacity: 0.65;
    color: #ffffff;
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgb(36,36,36);
  background: linear-gradient(0deg, rgba(36,36,36,0.41220238095238093) 0%, rgba(255,255,255,0) 100%);
  z-index: 1;
`;

const ImageHolder = styled.div`
  position: relative;
  -webkit-box-shadow: 0 8px 30px 1px #6b6b6b;
	   -moz-box-shadow: 0 8px 30px 1px #6b6b6b;
          box-shadow: 0 8px 30px 1px #6b6b6b;
  margin: 40px;
  border-radius: 5px;
`;

const TextHolder = styled.div`
  max-width: 450px;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 100px;

  @media screen and (max-width: 965px) {
    margin-left: 20px;
  }
`;

export default ReferenceSlide;
