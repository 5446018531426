import React, { Component } from 'react';
import styled from 'styled-components';
import { buttonBgColor, buttonBgColorHover, smoothTransition } from '../../styles/variables';

import member_1 from '../../assets/images/member_1.jpg';
import member_2 from '../../assets/images/member_2.jpg';
import member_3 from '../../assets/images/member_3.jpg';

import iconLinkedIn from '../../assets/images/icon-linkedIn.svg';
import iconMail from '../../assets/images/icon-mail.svg';

import { getBlocksByOverFolderId } from '../../functions/blocks';
import Loading from '../../components/Loading';

class MemberCard extends Component {

    constructor(props) {
        super(props);
        this.state = {teamBlock:undefined};
    }

    componentDidMount = () => {
        getBlocksByOverFolderId(8).then(teamBlock => {
            this.setState({teamBlock: teamBlock.data.data});
        });
    }

    render() {
        const { teamBlock } = this.state;
        const { id } = this.props;
        const orientation = (!this.props.orientation) ? 'left' : this.props.orientation;
        const memberImages = [
            {id: 1, image: member_1},
            {id: 2, image: member_2},
            {id: 3, image: member_3}
        ];
  
        let member = {name: null, description: null, linkedIn: null, email: null, image: null};
        if(teamBlock){
            let memberInfo = (teamBlock.folders[id-1]) ? teamBlock.folders[id-1] : null;
            if(memberInfo !== null){
                memberInfo.blocks.map(info => {
                    if(info["name"] === "Name"){
                        member.name = info["content"];
                        return true;
                    }else if(info["name"] === "Description"){
                        member.description = info["content"];
                        return true;
                    }else if(info["name"] === "LinkedIn"){
                        member.linkedIn = info["content"];
                        return true;
                    }else if(info["name"] === "Email"){
                        member.email = info["content"];
                        return true;
                    }
                    return false;
                });
                memberImages.map(image => {
                    if(image.id === id){
                        member.image = image["image"];
                        return true;
                    }
                    return null;
                });
            }
        }

        return (
            <Card className={orientation}>
                <div className="text">
                    <h3>{member.name}</h3>
                    <p>{member.description ? member.description : <Loading />}</p>
                    <Social>
                        {member.linkedIn ? <a href={member.linkedIn} className="anchor"><img src={iconLinkedIn} alt="" /></a> : null}
                        {member.email ? <a href="mailto:{member.email}" className="anchor"><img src={iconMail} alt="" /></a> : null}
                    </Social>
                </div>
                <div className="image">
                    <img src={member.image} alt={member.name} />
                </div>
            </Card>
        );
    }
}

const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 0;
  width: 100%;
  max-width: 860px;
  margin: 0 auto 60px;

  &.right{
    .text{
        padding-right: auto;
        padding-left: 30px;
        text-align: right;
        order: 2;
    }
  }
  .text{
    width: calc(100% - 400px);
    box-sizing: border-box;
    padding-right: 30px;
    text-align: left;
    h3{
        margin-bottom: 20px;
    }
  }
  .image{
    width: 400px;
    box-shadow: 0 12px 36px rgba(37, 17, 48, 0.08);
    background-color: #f2f8ff;
    border-radius: 8px;
    img{
        border-radius: inherit;
    }
  }

    @media only screen and (max-width: 900px){
        flex-direction: column;
        padding: 0;

        .text{
            order: 2;
            padding: 0!important;
            width: 100%;
            text-align: center!important;

            h3{
                margin-bottom: 0;
            }
        }
        .image{
            height: 200px;
            width: auto;
            margin-bottom: 25px;
            order: 1;
            img{
                max-height: 100%;
            }
        }
    }
`;

const Social = styled.div`
    margin-top: 25px;
    .anchor{
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: ${buttonBgColor};
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 20px;
        transition: ${smoothTransition};
        &:hover{
            background-color: ${buttonBgColorHover}
        }
    }

    @media only screen and (max-width: 900px){
        .anchor{
            margin: 0 10px;
        }
    }
`;

export default MemberCard;
