import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import './fonts/Gilroy-Bold/Gilroy-Bold.ttf';
import './fonts/Gilroy-ExtraBold/Gilroy-ExtraBold.ttf';
import './fonts/Gilroy-SemiBold/Gilroy-SemiBold.ttf';


// Only for dev env - no needed on prod
axios.defaults.headers.common["Accept"] = "aaaaa";  // low priority

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
