import React, { Component } from 'react';
import LogoImage from '../../assets/images/logo.svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

class Logo extends Component {

  render() {
    const { path } = this.props;
    return <Link to={path}><StyledLogo src={LogoImage} alt="Appsec logo"/></Link>
  }

}

const StyledLogo = styled.img`
  height: 40px;
`;

export default Logo;
