import React, { Component } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Loading from '../../components/Loading'

import { Container } from '../../styles/variables';

class HomepageIconsSet extends Component {
  render() {
    const { columns, header, description, horizontal } = this.props;
    return (
      <Wrapper>
        <Header>{header ? header : <Loading/>}</Header>
        <Description>{description}</Description>
        { columns.map(column => {
             return (
             <Block key={column.id} horizontal={horizontal}>
              <LazyLoad>
                <img src={column.icon} alt=""/>
              </LazyLoad>
              <Label>{column.label}</Label>
              <Heading>{column.heading}</Heading>
              <Content horizontal={horizontal}>
                {column.body}
              </Content>
             </Block>
             );
          })
        }
      </Wrapper>
    );
  }
}

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 100px;

  @media only screen and (max-width: 900px){
    padding: 25px 0 40px;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: ${props => props.horizontal ? 'row' : 'column'};
  justify-content: flex-start;
  align-items: ${props => props.horizontal ? 'center' : 'flex-start'};
  margin: 20px;
  width: ${props => props.horizontal ? 'calc((100% / 2 - 40px))' : 'calc((100% / 4 - 40px))'};


  @media only screen and (max-width: 900px){
    width: calc((100% / 2 - 40px));
    text-align: center;
    align-items: center;
  }
  @media only screen and (max-width: 768px){
    img{
      max-width: 70px;
    }
  }
  @media only screen and (max-width: 600px){
    width: calc((100% - 40px));
  }
`;

const Label = styled.div`
  opacity: 0.4;
  color: #ffffff;
  font-family: "Gilroy-Bold";
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;
  margin-top: 20px;

`;

const Heading = styled.h2`
  color: #ffffff;
  font-family: "Gilroy-ExtraBold";
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
`;

const Content = styled.p`
  opacity: 0.65;
  color: #ffffff;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-top: ${props => props.horizontal ? '0' : '15px'};
  margin-left: ${props => props.horizontal ? '25px' : '0'};
  ${({ horizontal }) => horizontal && `
    font-family: "Gilroy-Bold";
    font-size: 20px;
    font-weight: 700;
    opacity: 1;
  `}
`;

const Header = styled.h2`
  width: 100%;
  text-align: center;
  color: #ffffff;
  margin: 100px 0;
  line-height: 72px;

  @media only screen and (max-width: 900px){
    margin: 40px 0 25px;
    line-height: normal;
  }
`;

const Description = styled.p`
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin: -60px auto 70px;

  @media only screen and (max-width: 900px){
    margin: 0px auto 60px;
  }
`;

export default HomepageIconsSet;
