import React, { Component } from 'react';
import { globalNegativeMargin } from '../../styles/variables';
import contactBackground from '../../assets/images/contact.jpg'

import Layout from '../Layout/index';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import WhiteSection from '../../components/WhiteSection/index';
import CenteredSection from '../../components/CenteredSection/index';
import GraySection from '../../components/GraySection/index';
import Form from '../../components/Form/index';
import ContactSection from '../../components/ContactSection/index';
import Footer from '../../components/Footer/index';
import TryDemoSection from "../../components/TryDemoSection/index";

import { getBlocksByOverFolderId } from '../../functions/blocks';
import Loading from '../../components/Loading';
import SocialImage from '../../assets/images/social.png';
import Helmet from 'react-helmet';
import { MainTitle } from '../../constants/global';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {contactInfo:undefined};
    }

    componentDidMount = () => {
        getBlocksByOverFolderId(9).then(contactInfo => {
            this.setState({contactInfo: contactInfo.data.data});
        });
    }

    render() {
        const { location } = this.props;
        const { contactInfo } = this.state;

        const heading = contactInfo ? contactInfo.folders[0].blocks[0].content : null;
        const subheading = contactInfo ? contactInfo.folders[0].blocks[1].content : null;

        const contactBottomPadding = '70px';

        const styleGraySection = {
            flexDirection: 'column',
        };

        return (
            <Layout location={location}>
               <Helmet>
                <meta charSet="utf-8" />
                <title>Kontakt | {MainTitle}</title>
                <meta property="og:image" content={SocialImage} />
              </Helmet>
                <Header background={contactBackground} isBackgroundVideo={false} fullHeight={true}>
                    <HeaderContent
                        heading={heading}
                        subheading={subheading}
                        isCentered={false}
                    />
                </Header>
                <GraySection customStyle={styleGraySection}>
                    <WhiteSection transparentBg={true} paddingBottom={contactBottomPadding}>
                        <CenteredSection marginTop={globalNegativeMargin} boxShadow={true}>
                            <Form comment={true} />
                        </CenteredSection>
                    </WhiteSection>
                    <ContactSection>
                        <h2>{contactInfo ? contactInfo.folders[1].blocks[0].name : <Loading />}</h2>
                        <p className="description">{contactInfo ? contactInfo.folders[1].blocks[0].content : null}</p>

                        <div className="contactBox">
                            <h3>{contactInfo ? contactInfo.folders[1].blocks[1].name : null}</h3>
                            <p>{contactInfo ? contactInfo.folders[1].blocks[1].content : null}</p>
                        </div>
                        <div className="contactBox">
                        <h3>{contactInfo ? contactInfo.folders[1].blocks[2].name : null}</h3>
                            <p>{contactInfo ? contactInfo.folders[1].blocks[2].content : null}</p>
                        </div>
                        <div className="contactBox">
                            <h3>{contactInfo ? contactInfo.folders[1].blocks[3].name : null}</h3>
                            <p>{contactInfo ? contactInfo.folders[1].blocks[3].content : null}</p>
                        </div>
                    </ContactSection>
                </GraySection>
                <Footer>
                    <TryDemoSection />
                </Footer>
            </Layout>
        )
    }
}

export default Contact;
