import React from 'react';
import styled from 'styled-components';

const SquareIcon = ({color}) => {
  return (
    <SquareIconWrapper color={color}>
      <div>
        <Span/>
        <Span/>
      </div>
      <div>
        <Span/>
        <Span/>
      </div>
    </SquareIconWrapper>
  );
};



const Span = styled('span')`
  width: 8px;
  height: 8px;
`;

const SquareIconWrapper = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  flex-direction: column;
  margin-bottom: 10px;

  div {
    display: flex;
  }

  ${Span} {
    background-color: ${props => props.color};
    margin-right: 1px;
    margin-bottom: 1px;
  }
`;

export default SquareIcon;
