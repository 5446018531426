import React, { Component } from 'react';
import styled from 'styled-components';

import Layout from '../Layout/index';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import WhiteSection from '../../components/WhiteSection/index';
import GraySection from '../../components/GraySection/index';
import CenteredSection from '../../components/CenteredSection/index';
import ArticlesRow from '../../components/ArticlesRow/index';
import AuthorBox from '../../components/AuthorBox/index';
import { getCaseStudyById } from '../../functions/caseStudy';
import Loading from '../../components/Loading';
import Helmet from 'react-helmet';
import Footer from '../../components/Footer/index';
import SocialImage from '../../assets/images/social.png';
import { MainTitle } from '../../constants/global';

import BackgroundImage from '../../assets/images/study.jpg'

class CaseStudy extends Component {
  constructor(props) {
    super(props);
    this.state = {caseStudy: undefined};
  }

  componentDidMount = () => {
    const { match } = this.props;
    getCaseStudyById(match.params[0]).then(data => {
      this.setState({caseStudy: data.data.data});
    });
  }

  render() {
    const { caseStudy } = this.state;
    const { location } = this.props;
    const heading = caseStudy && caseStudy.title;
    const articles = [
        {
            title: "Challenges",
            description: ( caseStudy && <div dangerouslySetInnerHTML={{__html: caseStudy.description_1}}/>)
        },
        {
            title: "Solution",
            description: ( caseStudy && <div dangerouslySetInnerHTML={{__html: caseStudy.description_2}}/>)
        },
        {
            title: "Benefits",
            description: ( caseStudy && <div dangerouslySetInnerHTML={{__html: caseStudy.description_3}}/>)
        }
      ];

    return (
      <Layout location={location}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{caseStudy ? caseStudy.title : 'Blog article'} | {MainTitle}</title>
          <meta name="description" content={caseStudy ? caseStudy.meta_description : 'Blog description'} />
          <meta property="og:image" content={SocialImage} />
        </Helmet>
        <Header background={BackgroundImage} isBackgroundVideo={false}>
          <HeaderContent
            heading={heading}
            isCentered={true}
          />
        </Header>
        <GraySection>
            <ArticlesRow articles={articles} onlyTextColumns={true} disableButton={true} />
        </GraySection>
        <WhiteSection>
          <CenteredSection className="styledContent startAligned">
                <ContentBox>
                    <ContentColumn>
                      { caseStudy ? <div dangerouslySetInnerHTML={{__html: caseStudy.body1}}/> : <Loading/>}
                    </ContentColumn>
                    { caseStudy && caseStudy.feedback[0] &&
                      <AuthorBox
                        name={`${caseStudy.feedback[0].first_name} ${caseStudy.feedback[0].last_name}`}
                        position={caseStudy.feedback[0].rank}
                        avatar={caseStudy.feedback[0].avatar}
                        reference={caseStudy.feedback[0].text}
                      />
                    }
                </ContentBox>
                <ContentBox>
                    <h2>Excelling at Virtual Desktop Security</h2>
                    { caseStudy ? <div dangerouslySetInnerHTML={{__html: caseStudy.body2}}/> : <Loading/>}
                </ContentBox>
            </CenteredSection>
        </WhiteSection>
        <Footer/>
      </Layout>
    )
  }
}

const ContentBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 70px;
`;

const ContentColumn = styled.div`
    width: 50%;
    box-sizing: border-box;
    padding-right: 30px;
    &:nth-of-type(even){
        padding-right: 0;
    }
`;

export default CaseStudy;
