import React, { Component } from 'react';
import Layout from '../Layout/index';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import Video from '../../components/Video';
import screenDemo from '../../assets/videos/screenDemo.mp4';
import WhiteSection from '../../components/WhiteSection/index';
import CenteredSection from '../../components/CenteredSection/index';
import LightIconsColumns from '../../components/LightIconsColumns';
import BlueGradientSection from '../../components/BlueGradientSection';
import Footer from '../../components/Footer';

import BackgroundImage from '../../assets/images/product.jpg'
import HomepageIconsSet from '../../components/HomepageIconsSet';
import TryDemoSection from '../../components/TryDemoSection';
import ProductNumberCards from '../../components/ProductNumberCards';

import PagesRow from '../../components/PagesRow';

import ProductShieldIcon from '../../components/HomepageIconsSet/img/product_shield_icon.png';
import ProductSearchIcon from '../../components/HomepageIconsSet/img/product_search_icon.png';
import ProductReloadIcon from '../../components/HomepageIconsSet/img/product_reload_icon.png';

import Atom from '../../components/HomepageIconsSet/img/atom.png';
import ShieldRounded from '../../components/HomepageIconsSet/img/shieldRounded.png';
import BugIcon from '../../components/HomepageIconsSet/img/bug.png';
import BrowserIcon from '../../components/HomepageIconsSet/img/browser.png';
import DocumentIcon from '../../components/HomepageIconsSet/img/document.png';
import DriveIcon from '../../components/HomepageIconsSet/img/drive.png';
import ScriptsIcon from '../../components/HomepageIconsSet/img/scripts.png';
import ShieldIcon from '../../components/HomepageIconsSet/img/shield.png';

import { PATH_DEMO } from '../../routes/routePaths';

import { getBlocksByOverFolderId } from '../../functions/blocks';
import { getPagesByCategory } from '../../functions/pages';
import Loading from '../../components/Loading';
import SocialImage from '../../assets/images/social.png';
import Helmet from 'react-helmet';
import { MainTitle } from '../../constants/global';

class Product extends Component {

  constructor(props) {
    super(props);
    this.state = {blocks: undefined, pages: undefined};
  }

  componentDidMount = () => {
    getBlocksByOverFolderId(2).then(blocks => {
      this.setState({blocks: blocks.data.data});
    });

    getPagesByCategory(5).then(pages => {
      this.setState({pages: pages.data.data});
    });

  }

  getPart = (string, splitString = '@', returnPart = 0) => {
    const splited = string.split(splitString);
    return splited[returnPart];
  }

  render() {
    const { location } = this.props;
    const { blocks, pages } = this.state;

    const heading = blocks ? blocks.folders[0].blocks[0].content : null;
    const subheading = blocks ? blocks.folders[0].blocks[1].content : null;
    const buttonText = blocks ? blocks.folders[0].blocks[2].content : null;
    console.log(blocks)
    const columns1 = blocks ?  [
      {
        id: 1,
        icon: ScriptsIcon,
        label: this.getPart(blocks.folders[3].blocks[0].name, '@', 1),
        heading: this.getPart(blocks.folders[3].blocks[0].name, '@', 0),
        body: blocks.folders[3].blocks[0].content
      },
      {
        id: 2,
        icon: BugIcon,
        label: this.getPart(blocks.folders[3].blocks[1].name, '@', 1),
        heading: this.getPart(blocks.folders[3].blocks[1].name, '@', 0),
        body: blocks.folders[3].blocks[1].content
      },
      {
        id: 3,
        icon: DocumentIcon,
        label: this.getPart(blocks.folders[3].blocks[2].name, '@', 1),
        heading: this.getPart(blocks.folders[3].blocks[2].name, '@', 0),
        body: blocks.folders[3].blocks[2].content
      },
      {
        id: 4,
        icon: BrowserIcon,
        label: this.getPart(blocks.folders[3].blocks[3].name, '@', 1),
        heading: this.getPart(blocks.folders[3].blocks[3].name, '@', 0),
        body: blocks.folders[3].blocks[3].content
      },
      {
        id: 5,
        icon: ShieldIcon,
        label: this.getPart(blocks.folders[3].blocks[4].name, '@', 1),
        heading: this.getPart(blocks.folders[3].blocks[4].name, '@', 0),
        body: blocks.folders[3].blocks[4].content
      },
      {
        id: 6,
        icon: DriveIcon,
        label: this.getPart(blocks.folders[3].blocks[5].name, '@', 1),
        heading: this.getPart(blocks.folders[3].blocks[5].name, '@', 0),
        body: blocks.folders[3].blocks[5].content
      },
      {
        id: 7,
        icon: Atom,
        label: this.getPart(blocks.folders[3].blocks[7].name, '@', 1),
        heading: this.getPart(blocks.folders[3].blocks[7].name, '@', 0),
        body: blocks.folders[3].blocks[7].content
      },
      {
        id: 8,
        icon: ShieldRounded,
        label: this.getPart(blocks.folders[3].blocks[8].name, '@', 1),
        heading: this.getPart(blocks.folders[3].blocks[8].name, '@', 0),
        body: blocks.folders[3].blocks[8].content
      }
    ] : [];

    const columns3 = blocks ? [
      {
        id: 1,
        icon: ProductShieldIcon,
        heading:  blocks.folders[1].blocks[2].name,
        body: blocks.folders[1].blocks[2].content
      },
      {
        id: 2,
        icon: ProductSearchIcon,
        heading:  blocks.folders[1].blocks[3].name,
        body: blocks.folders[1].blocks[3].content
      },
      {
        id: 3,
        icon: ProductReloadIcon,
        heading:  blocks.folders[1].blocks[4].name,
        body: blocks.folders[1].blocks[4].content
      }
    ] : [];

    return (
      <Layout location={location}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Produkt | {MainTitle}</title>
          <meta property="og:image" content={SocialImage} />
        </Helmet>
        <Header background={BackgroundImage} isBackgroundVideo={false} fullHeight={true}>
          <HeaderContent
            heading={heading}
            subheading={subheading}
            isCentered={false}
            buttonLink={PATH_DEMO}
            buttonText={buttonText}
          />
        </Header>
        <WhiteSection>
          <CenteredSection>
            <h2 className="mt70">{blocks ? blocks.folders[1].blocks[0].content : <Loading />}</h2>
            <p className="subheading">{blocks ? blocks.folders[1].blocks[1].content : null}</p>
            <Video video={screenDemo} marginTop="80px" isHiddenOnMobile={true} withShadow={true} />
            <LightIconsColumns data={columns3} />
          </CenteredSection>
        </WhiteSection>
        <BlueGradientSection>
          <TryDemoSection allowTick={true} />
        </BlueGradientSection>
        <WhiteSection>
          <ProductNumberCards texts={blocks ? blocks.folders[2] : undefined} />
        </WhiteSection>
        <BlueGradientSection>
          <HomepageIconsSet columns={columns1} header={blocks ? blocks.folders[3].blocks[6].content : null} />
        </BlueGradientSection>
        {/* <WhiteSection>
          {!pages ? <Loading/> : <PagesRow columns={4} articles={pages}/>}
        </WhiteSection> */}
        <Footer>
          <TryDemoSection allowTick={true} />
        </Footer>
      </Layout>
    )
  }
}

export default Product;
