import React, { Component } from 'react';
import Layout from '../Layout/index';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import WhiteSection from '../../components/WhiteSection/index';
import CenteredSection from '../../components/CenteredSection/index';
import { globalNegativeMargin } from '../../styles/variables';
import LazyLoad from 'react-lazyload';

import Atom from '../../components/HomepageIconsSet/img/atom.png';
import ShieldRounded from '../../components/HomepageIconsSet/img/shieldRounded.png';
import BugIcon from '../../components/HomepageIconsSet/img/bug.png';
import BrowserIcon from '../../components/HomepageIconsSet/img/browser.png';
import DocumentIcon from '../../components/HomepageIconsSet/img/document.png';
import DriveIcon from '../../components/HomepageIconsSet/img/drive.png';
import ScriptsIcon from '../../components/HomepageIconsSet/img/scripts.png';
import ShieldIcon from '../../components/HomepageIconsSet/img/shield.png';

import ShieldedIcon from '../../components/HomepageIconsSet/img/shielded.png';
import SearchIcon from '../../components/HomepageIconsSet/img/search.png';
import HouseIcon from '../../components/HomepageIconsSet/img/house.png';
import CloudIcon from '../../components/HomepageIconsSet/img/cloud.png';

import homepageScreen from '../../assets/images/HomePage@1x.jpg'
import screenDemo from '../../assets/videos/screenDemo.mp4'
import whiteSectionImage from '../../assets/images/homepage_white_section.jpg'
import GraySection from '../../components/GraySection';
import BlueGradientSection from '../../components/BlueGradientSection';
import PagesRow from '../../components/PagesRow';
import HomepageIconsSet from '../../components/HomepageIconsSet';
import Video from '../../components/Video';
import BlocksWithUnderlineHeading from '../../components/BlocksWithUnderlineHeading';
import Footer from '../../components/Footer/index';
import TryDemoSection from "../../components/TryDemoSection/index";
import { getBlocksByOverFolderId } from '../../functions/blocks';
import { getPagesByCategory } from '../../functions/pages';
import { PATH_DEMO } from '../../routes/routePaths';
import Loading from '../../components/Loading';
import ReferencesSection from '../../components/ReferencesSection';
import SocialImage from '../../assets/images/social.png';
import Helmet from 'react-helmet';
import { MainTitle } from '../../constants/global';

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {blocks: undefined, pages: undefined};
  }

  componentDidMount = () => {
    getBlocksByOverFolderId(1).then(blocks => {
      this.setState({blocks: blocks.data.data});
    });

    getPagesByCategory(4).then(pages => {
      this.setState({pages: pages.data.data});
    });
  }

  render() {

    const { location } = this.props;
    const { blocks, pages } = this.state;
    const heading = blocks ? blocks.folders[0].blocks[0].content : null;
    const subheading = blocks ? blocks.folders[0].blocks[1].content : null;
    const buttonText = blocks ? blocks.folders[0].blocks[2].content : null;
    const columns1 = blocks ? [
      {
        id: 1,
        icon: ScriptsIcon,
        heading: blocks.folders[2].blocks[0].content,
        body: blocks.folders[2].blocks[2].content
      },
      {
        id: 2,
        icon: BugIcon,
        heading: blocks.folders[3].blocks[0].content,
        body: blocks.folders[3].blocks[2].content
      },
      {
        id: 3,
        icon: DocumentIcon,
        heading: blocks.folders[4].blocks[0].content,
        body: blocks.folders[4].blocks[2].content
      },
      {
        id: 4,
        icon: BrowserIcon,
        heading: blocks.folders[5].blocks[0].content,
        body: blocks.folders[5].blocks[2].content
      },
      {
        id: 5,
        icon: ShieldIcon,
        heading: blocks.folders[6].blocks[0].content,
        body: blocks.folders[6].blocks[2].content
      },
      {
        id: 6,
        icon: DriveIcon,
        heading: blocks.folders[7].blocks[0].content,
        body: blocks.folders[7].blocks[2].content
      },
      {
        id: 7,
        icon: Atom,
        heading: blocks.folders[16].blocks[0].content,
        body: blocks.folders[16].blocks[1].content
      },
      {
        id: 8,
        icon: ShieldRounded,
        heading: blocks.folders[17].blocks[0].content,
        body: blocks.folders[17].blocks[1].content
      }
    ] : [];

    const columns2 = blocks ? [
      {
        id: 1,
        icon: ShieldedIcon,
        label: blocks.folders[11].blocks[1].content,
        heading: blocks.folders[11].blocks[0].content,
        body: blocks.folders[11].blocks[2].content
      },
      {
        id: 2,
        icon: SearchIcon,
        label: blocks.folders[12].blocks[1].content,
        heading: blocks.folders[12].blocks[0].content,
        body: blocks.folders[12].blocks[2].content
      },
      {
        id: 3,
        icon: HouseIcon,
        label: blocks.folders[13].blocks[1].content,
        heading: blocks.folders[13].blocks[0].content,
        body: blocks.folders[13].blocks[2].content
      },
      {
        id: 4,
        icon: CloudIcon,
        label: blocks.folders[14].blocks[1].content,
        heading: blocks.folders[14].blocks[0].content,
        body: blocks.folders[14].blocks[2].content
      }
    ] : [];

    return (
      <Layout location={location}>
         <Helmet>
          <meta charSet="utf-8" />
          <title>{MainTitle}</title>
          <meta property="og:image" content={SocialImage} />
        </Helmet>
        <Header background={homepageScreen} isBackgroundVideo={false} fullHeight={false}>
          <HeaderContent
            heading={heading}
            subheading={subheading}
            buttonLink={PATH_DEMO}
            buttonText={buttonText}
            isCentered={false}
          />
        </Header>
        <WhiteSection>
          <CenteredSection marginTop={globalNegativeMargin}>
            <h1>{blocks ? blocks.folders[1].blocks[0].content : <Loading/>}</h1>
            <p>{blocks ? blocks.folders[1].blocks[1].content : ''}</p>
            <LazyLoad>
              <img src={whiteSectionImage} alt="" className="mt70 mb70"/>
            </LazyLoad>
          </CenteredSection>
        </WhiteSection>
        {/* <GraySection>
          {!pages ? <Loading/> : <PagesRow columns={3} articles={pages}/>}
        </GraySection> */}
        <BlueGradientSection>
          <HomepageIconsSet columns={columns1} header={blocks ? blocks.folders[8].blocks[0].content : null} />
        </BlueGradientSection>
        <WhiteSection>
          <CenteredSection>
            <h2 className="mt70">{blocks ? blocks.folders[9].blocks[0].content : <Loading/>}</h2>
            <Video video={screenDemo} marginTop="80px" isHiddenOnMobile={true} withShadow={true} />
            <BlocksWithUnderlineHeading blocks={blocks ? blocks.folders[9] : null}/>
          </CenteredSection>
        </WhiteSection>
        <BlueGradientSection>
          <HomepageIconsSet columns={columns2} header={blocks ? blocks.folders[10].blocks[0].content : null} />
        </BlueGradientSection>
        <ReferencesSection heading={blocks ? blocks.folders[15].blocks[0].content : <Loading/>}/>
        <Footer>
          <TryDemoSection />
        </Footer>
      </Layout>
    )
  }
}

export default Homepage;
