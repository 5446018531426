export const WebinarsColor = `#6b46ec`;
export const ReportsColor = `#efdb85`;
export const InfografikColor = `#e25757`;
export const CaseStudiesColor = `#67bc56`;
export const DataSheetsColor = `#f5a251`;
export const EbooksColor = `#ba89d4`;
export const ArticleColor = `#788df9`;

export const data = [
  {
    id: 1,
    color: '#000000',
    name: 'All'
  },
  {
    id: 2,
    color: '#ea8ab6',
    name: 'White Papers'
  },
  {
    id: 3,
    color: InfografikColor,
    name: 'Infographic'
  },
  {
    id: 4,
    color: CaseStudiesColor,
    name: 'Case Studies'
  },
  {
    id: 5,
    color: EbooksColor,
    name: 'eBooks'
  },
  {
    id: 6,
    color: DataSheetsColor,
    name: 'Datasheets'
  },
  {
    id: 8,
    color: ReportsColor,
    name: 'Reports'
  },
  {
    id: 9,
    color: WebinarsColor,
    name: 'Webinars'
  }
];

export const cards = [
  {
    id: 1,
    text: 'Hitachi Consulting Protects Their Global Remote Workforce with Endpoint Protection',
    linkText: 'Read More',
    link: '/',
    color: WebinarsColor
  },
  {
    id: 2,
    text: 'Financial Institutions Must Adopt Advanced Protection-Or Lose Billions',
    linkText: 'Read More',
    link: '/',
    color: ReportsColor
  },
  {
    id: 3,
    text: '5 Ways A CISO Can Tackle The Cybersecurity Skills Shortage Now',
    linkText: 'Read More',
    link: '/',
    color: InfografikColor
  },
  {
    id: 4,
    text: '5 Common Cyber Security Threats That Bypass Your Anti Virus',
    linkText: 'Read More',
    link: '/',
    color: InfografikColor
  },
  {
    id: 5,
    text: 'SentinelOne Protects TGI Fridays from Headquarters to the Table',
    linkText: 'Read More',
    link: '/',
    color: CaseStudiesColor
  },
  {
    id: 6,
    text: 'Extending Windows Defender ATP to MacOS and Linux with SentinelOne',
    linkText: 'Read More',
    link: '/',
    color: DataSheetsColor
  },
  {
    id: 7,
    text: 'SentinelOne Q4 Feature Update – No more reasons to stay on Legacy AV',
    linkText: 'Read More',
    link: '/',
    color: WebinarsColor
  },
  {
    id: 8,
    text: 'eBook: 90 Days – A CISO’s Journey to Impact – Volume II How to Drive Success',
    linkText: 'Read More',
    link: '/',
    color: EbooksColor
  },
  {
    id: 9,
    text: 'eBook: 90 Days – A CISO’s Journey to Impact – Volume II How to Drive Success',
    linkText: 'Read More',
    link: '/',
    color: InfografikColor
  },
  {
    id: 11,
    text: 'Kelsey-Seybold Selects SentinelOne to Provide Protected Care',
    linkText: 'Read more',
    link: '/',
    color: ReportsColor
  },
  {
    id: 12,
    text: 'FIMBank Augments Their SOC with SentinelOne’s Vigilance MDR',
    linkText: 'Read more',
    link: '/',
    color: WebinarsColor
  },
];
