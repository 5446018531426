export const API_FOLDER = '/api/folders/';
export const API_OVERFOLDER = '/api/overfolders/';
export const API_POSTS = '/api/posts/';
export const API_PAGES = '/api/pages/';
export const API_PAGES_BY_CAT = '/api/events-by-tag/';
export const API_CASESTUDY = '/api/trainings/';
export const API_SUBMITFORM = '/api/submitForm';
export const API_SOURCES = '/api/sources';
export const API_LABELS = '/api/tags';
export const API_FEEDBACKS = '/api/feedbacks';
