import React, { Component } from 'react';
import Layout from '../Layout/index';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import WhiteSection from '../../components/WhiteSection/index';
import CenteredSection from '../../components/CenteredSection/index';

import BackgroundImage from '../../assets/images/article.jpg'
import { getPostById } from '../../functions/posts';
import Loading from '../../components/Loading';
import Helmet from 'react-helmet';
import Footer from '../../components/Footer/index';
import SocialImage from '../../assets/images/social.png';
import { MainTitle } from '../../constants/global';

class Article extends Component {

  constructor(props) {
    super(props);
    this.state = {post: undefined};
  }

  componentDidMount = () => {
    const { match } = this.props;
    getPostById(match.params[0]).then(post => {
      this.setState({post: post.data.data});
    });
  }

  render() {
    const { location } = this.props;
    const { post } = this.state;

    const heading = post && post.title


    return (
      <Layout location={location}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{post ? post.title : 'Blog article'} | {MainTitle}</title>
          <meta name="description" content={post ? post.meta_description : 'Blog description'} />
          <meta property="og:image" content={SocialImage} />
        </Helmet>
        <Header background={BackgroundImage} isBackgroundVideo={false} compactHeader>
          <HeaderContent
            heading={heading}
            isCentered={true}
            compactHeader
          />
        </Header>
        <WhiteSection>
          <CenteredSection className="styledContent startAligned">
            { post ? <div dangerouslySetInnerHTML={{__html: post.body}}/> : <Loading/>}
          </CenteredSection>
        </WhiteSection>
        <Footer />
      </Layout>
    )
  }
}

export default Article;
