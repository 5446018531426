import  React from 'react';
import styled from 'styled-components';

const Video = ({ video, marginTop, withShadow, isHiddenOnMobile = true }) => {
  return (
    <Wrapper className={withShadow && 'videoBoxShadow'} isHiddenOnMobile={isHiddenOnMobile} marginTop={marginTop} >
      <video autoPlay={true} muted={true} width="100%" playsInline webkitPlaysInline>
        <source src={video} type="video/mp4"/>
      </video>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: ${props => props.marginTop};
  @media screen and (max-width: 768px) {
    display: ${props => props.isHiddenOnMobile ? 'none' : 'initial'};
  }
`;

export default Video;
