import React, { Component } from 'react';
import styled from 'styled-components';
import Loading from '../../components/Loading';
import { CaseStudiesColor, ArticleColor } from '../../components/Labels/data';

class Labels extends Component {
  render() {
    const { labels, activeIndex, handleActiveIndex } = this.props;
    return (
      <Wrapper>
        { labels ?
        <div>
          <Label
              active={null === activeIndex}
              key={9999}
              onClick={() => handleActiveIndex(null)}
              color={'#333'}
              >{"Vše"}
          </Label>
        {
          labels.map(label =>
            (label.id !== 4 && label.id !== 5 ?
            <Label
              active={label.id === activeIndex}
              key={label.id}
              onClick={() => handleActiveIndex(label.id)}
              color={label.color}
              >{label.name}
            </Label> :
            null
            )
          )
        }
          <Label
              active={999 === activeIndex}
              key={999}
              onClick={() => handleActiveIndex(999)}
              color={ArticleColor}
              >{"Článek"}
          </Label>
          <Label
              active={998 === activeIndex}
              key={998}
              onClick={() => handleActiveIndex(998)}
              color={CaseStudiesColor}
              >{"Případová studie"}
          </Label>
        </div>
        :
        <Loading/>
        }
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
& > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 25px;

    @media screen and (max-width: 900px){
      justify-content: center;
    }
  }
`;

const Label = styled.div`
  color: ${props => props.active ? '#000' : '#818a96'};
  font-family: ${props => props.active ? "Gilroy-Bold" : "Gilroy-SemiBold"};
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  padding: 0 30px;
  margin: 5px 0;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -5px;
    border-radius: 3px;
    width: 10px;
    height: 10px;
    background-color: ${props => props.color};
  }
`;

export default Labels;
