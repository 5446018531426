import React, { Component } from 'react';
import { globalNegativeMargin } from '../../styles/variables';
import demoBackground from '../../assets/images/freeTrial.jpg'

import Layout from '../Layout/index';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import WhiteSection from '../../components/WhiteSection/index';
import CenteredSection from '../../components/CenteredSection/index';
import GraySection from '../../components/GraySection/index';
import Form from '../../components/Form/index';
import Footer from '../../components/Footer/index';

import { getBlocksByOverFolderId } from '../../functions/blocks';
import SocialImage from '../../assets/images/social.png';
import Helmet from 'react-helmet';
import { MainTitle } from '../../constants/global';

class Demo extends Component {
    constructor(props) {
        super(props);
        this.state = {demoBlocks:undefined};
    }

    componentDidMount = () => {
        getBlocksByOverFolderId(10).then(demoBlocks => {
            this.setState({demoBlocks: demoBlocks.data.data});
        });
    }

    render() {
        const { location } = this.props;
        const { demoBlocks } = this.state;

        const heading = demoBlocks ? demoBlocks.folders[0].blocks[0].content : null;
        const subheading = demoBlocks ? demoBlocks.folders[0].blocks[1].content : null;

        const advantagesList = [
            (demoBlocks ? demoBlocks.folders[1].blocks[0].content : null),
            (demoBlocks ? demoBlocks.folders[1].blocks[1].content : null),
            (demoBlocks ? demoBlocks.folders[1].blocks[2].content : null),
            (demoBlocks ? demoBlocks.folders[1].blocks[3].content : null),
        ];

        const contactBottomPadding = '70px';

        const styleGraySection = {
            flexDirection: 'column',
        };

        return (
            <Layout location={location}>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Demo | {MainTitle}</title>
                <meta property="og:image" content={SocialImage} />
              </Helmet>
                <Header background={demoBackground} isBackgroundVideo={false} fullHeight={true}>
                    <HeaderContent
                        heading={heading}
                        subheading={subheading}
                        isCentered={false}
                        advantagesList={advantagesList}
                    />
                </Header>
                <GraySection customStyle={styleGraySection}>
                    <WhiteSection transparentBg={true} paddingBottom={contactBottomPadding}>
                        <CenteredSection marginTop={globalNegativeMargin} boxShadow={true}>
                            <Form buttonText={'Získat zkušební verzi'} isDemoForm={true} />
                        </CenteredSection>
                    </WhiteSection>
                </GraySection>
                <Footer/>
            </Layout>
        )
    }
}

export default Demo;
