import React from 'react';
import styled from 'styled-components';
import Antivirus from '../../assets/images/antivirus.png';
import LazyLoad from 'react-lazyload';
import SocialImage from '../../assets/images/social.png';
import Helmet from 'react-helmet';
import { MainTitle } from '../../constants/global';
import Layout from '../../containers/Layout';

const NotFound = ({location}) => {
  return (
    <Layout location={location}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nenalezeno | {MainTitle}</title>
        <meta property="og:image" content={SocialImage} />
      </Helmet>
      <FullHeightWrapper>
        <LazyLoad>
          <img src={Antivirus} alt="" className="mt70 mb70"/>
        </LazyLoad>
        <h1>404</h1>
        <h2>Stránka nenalezena</h2>
        <p>Omlouváme se, nenašli jsme stránku, kterou jste hledali.<br/>Vraťte se zpátky, nebo na úvodní stranu.</p>
      </FullHeightWrapper>
    </Layout>
  );
};

const FullHeightWrapper = styled.div`
  height: 100vh;
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  padding: 110px 50px;

  h1 {
    font-family: 'Gilroy-ExtraBold';
    font-size: 80px;
    font-weight: 700;
    line-height: 80px;
    margin-bottom: 0;
  }
  h2 {
    font-family: 'Gilroy-SemiBold';
    font-size: 40px;
    font-weight: 600;
    line-height: 80px;
    margin-bottom: 10px;
  }

  p {
    font-size: 22px;
    font-weight: 300;
    line-height: 36px;
    opacity: 0.7;
    color: #ffffff;
  }
`;

export default NotFound;
