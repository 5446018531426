import axios from 'axios';
import {
  API_FOLDER,
  API_OVERFOLDER
} from '../routes/apiPaths';

export const getBlocksByOverFolderId = id => {
  return axios.get(`${API_OVERFOLDER}${id}?with[1]=folders.blocks`)
}

export const getBlocksByFolderId = id => {
  return axios.get(`${API_FOLDER}${id}?with=blocks`)
}