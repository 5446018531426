import React from 'react';
import styled from 'styled-components';
import { Container } from '../../styles/variables';

const ContactSection = ({ children }) => {
    return (
        <Wrapper>{children}</Wrapper>
    );
}

const Wrapper = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px;
  border-radius: 4px;
  z-index: 20;
  text-align: center;
  position: relative;
  
  h2{
    width: 100%;
  }
  .description{
    margin: 25px auto;
    width: 100%;
    padding: 0 315px;
  }
  .contactBox{
    width: 33%;
    margin: 40px auto;
    h3{
        margin-bottom: 24px;
        text-transform: uppercase;
    }
   
  }

  @media only screen and (max-width: 1200px){
    .description{
      width: 70%;
      padding: 0;
    }
  }   

  @media only screen and (max-width: 1020px){
    .description{
      width: 90%;
    }
    .contactBox{
      width: 100%;
      margin: 0px auto 30px;
    }
  }   
`;

export default ContactSection;
