import React from 'react';
import styled from 'styled-components';
import { Container, defaultLinkColor, blockQuoteColor, defaultTextColor, opacityTextColor } from '../../styles/variables';

import computersBg from '../../assets/images/computersBg.jpg';

const CenteredSection = ({ className, marginTop, transparent = false, boxShadow, children }) => {
  return (
    <Wrapper transparent={transparent} className={className} marginTop={marginTop} boxShadow={boxShadow}>{children}</Wrapper>
  );
}

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px;
  border-radius: 4px;
  z-index: 20;
  text-align: center;
  position: relative;
  background: ${props => props.transparent ? 'transparent' : '#FFF'};
  margin-top: ${props => props.marginTop};
  box-shadow: ${props => props.boxShadow ? '0 16px 24px rgba(37, 17, 48, 0.05)' : null};
  @media only screen and (max-width: 1024px){
    padding: 60px 25px;
  }
  @media only screen and (max-width: 768px){
    padding: 40px 0;
  }

  &.boxedContent{
    background: url(${computersBg});
    background-size: cover;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding: 90px 0 160px;
    h1, h2{
      width: 100%;
      margin-bottom: 80px;
      line-height: 72px;
    }
    .two-column{
      width: 50%;
      @media screen and (max-width: 998px) {
        width: 100%;
        &:nth-of-type(odd){
          margin-bottom: 20px;
        }
      }
      text-align: justify;
      box-sizing: border-box;
      &:nth-of-type(odd){
        padding-right: 5%;
      }
    }
  }

  &.startAligned{
    justify-content: flex-start;
    text-align: left;
  }

  &.styledContent{
    h2{
      width: 100%;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 25px;
      color: ${defaultTextColor};
    }
    h3{
      width: 100%;
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
      color: ${defaultTextColor};
    }
    h4{
      width: 100%;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
      color: ${defaultTextColor};
    }
    h5{
      width: 100%;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 15px;
      color: ${defaultTextColor};
    }
    p{
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 30px;
      b, strong{
        color: ${defaultTextColor};
      }
    }
    blockquote{
      width: 100%;
      color: ${blockQuoteColor};
      font-size: 18px;
      font-weight: 700;
      margin: 20px auto 35px;
      position: relative;
      &:before{
        content: "«";
        padding-right: 5px;
      }
      &:after{
        content: "»";
        padding-left: 5px;
      }
    }
    a{
      font-size: inherit;
      color: ${defaultLinkColor};
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
    ul, ol{
      width: 100%;
      padding-left: 35px;
      li{
        color: ${opacityTextColor};
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
      }
    }
    img{
      margin: 0 auto 30px;
      max-width: 80%;
    }
  }
`;

export default CenteredSection;
