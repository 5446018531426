import axios from 'axios';
import {
  API_PAGES,
  API_PAGES_BY_CAT
} from '../routes/apiPaths';

export const getPageById = id => {
  return axios.get(`${API_PAGES}${id}`)
}

export const getPagesByCategory = id => {
  return axios.get(`${API_PAGES_BY_CAT}${id}`)
}
