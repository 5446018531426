import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import {alertColor, inputRadius, inputBorderColor, inputTextColor, buttonBgColor, globalRadius, buttonBgColorHover} from '../../styles/variables';
import ArrowDown from '../../assets/images/down-arrow.png';
import {API_SUBMITFORM} from '../../routes/apiPaths';
import Loading from '../../components/Loading';

class Form extends Component{
    constructor(props) {
        super(props);
        this.state = {
            submitForm: null,
            returnMessage: 'Formulář nebylo možné odeslat. Zkontrolujte povinná pole a zkuste to znovu.',
            animation: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        this.setState({
            animation: true
        });

        axios.post(API_SUBMITFORM, data).then((response) => {
            let message = (this.props.isDemoForm) ? 'Děkujeme za Váš zájem! Budeme Vás kontaktovat.' : 'Formulář byl úspěšně odeslán. Děkujeme.';
            this.setState({
                submitForm: response.data.status,
                returnMessage: (response.data.status === true) ? message : this.state.returnMessage,
                animation: null
            });


            document.getElementById("formWrap").scrollIntoView({ behavior: 'smooth' });

            if(response.data.status === true){
                document.getElementById("formWrap").reset();
            }
        }).catch((error) => {
            this.setState({
                submitForm: false,
                animation: null
            });
        });
    }

    render(){
        const { submitForm, returnMessage, animation } = this.state;

        let comment = null;
        if(this.props.comment){
            comment = (
                <div className="full">
                    <label>Obsah zprávy:</label>
                    <textarea name="comments" />
                </div>
            )
        }

        let buttonText = (this.props.buttonText) ? this.props.buttonText : 'Odeslat';
        let formType = (this.props.isDemoForm) ? 'demo' : 'contact';

        return (
            <FormWrap id="formWrap" onSubmit={this.handleSubmit}>
                {(animation !== null) ? <div id="waitingDiv"><Loading /></div> : null}
                {(submitForm !== null) ? <div className="full returnMessage">{returnMessage}</div> : null}
                <div>
                    <label>Jméno: <b>*</b></label>
                    <input type="text" name="name" required />
                </div>
                <div>
                    <label>Příjmení: <b>*</b></label>
                    <input type="text" name="surname" required />
                </div>
                <div>
                    <label>Pracovní e-mail: <b>*</b></label>
                    <input type="email" name="email" required />
                </div>
                <div>
                    <label>Název společnosti: <b>*</b></label>
                    <input type="text" name="company" required />
                </div>
                <div>
                    <label>Telefonní číslo: <b>*</b></label>
                    <input type="tel" name="phone" required />
                </div>
                <div>
                    <label>Počet zaměstnanců ve vaší společnosti: <b>*</b></label>
                    <SelectWrapper>
                      <select name="employees" id="employees" required>
                          <option value="1-5">1-5</option>
                          <option value="6-10">6-10</option>
                          <option value="11-25">11-25</option>
                          <option value="26-50">26-50</option>
                          <option value="51-100">51-100</option>
                          <option value="101-500">101-500</option>
                          <option value="501-1000">501-1000</option>
                          <option value="nad 1000">nad 1000</option>
                      </select>
                    </SelectWrapper>
                </div>
                {comment}
                <input type="hidden" name="formType" value={formType} />
                <div className="full">
                    <button name="submit">{buttonText}</button>
                </div>
            </FormWrap>
        );
    }
}

export default Form;

const FormWrap = styled.form`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    scroll-margin: 140px;

    div{
        width: calc(50% - 24px);
        margin: 12px;
        text-align: left;
        &.full{
            width: 100%;
        }

        label{
            display: block;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 25px;
            b{
                color: ${alertColor}
            }
        }
        input, select, textarea{
            font-family: 'Raleway';
            border-radius: ${inputRadius};
            border: 2px solid ${inputBorderColor};
            width: 100%;
            padding: 18px 10px;
            font-size: 16px;
            font-weight: 600;
            color: ${inputTextColor};
            &:focus, &:active{
                outline: none;
            }
        }
        select {
          appearance: none;
        }
        input{
            width: calc(100% - 22px);
        }
        textarea{
            width: calc(100% - 22px);
            min-height: 112px;
        }
        button{
            font-family: 'Raleway';
            border-radius: ${globalRadius};
            border: 0;
            background-color: ${buttonBgColor};
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
            width: 100%;
            padding: 18px 10px;
            font-size: 16px;
            font-weight: 700;
            color: #FFF;
            text-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
            max-width: 248px;
            cursor: pointer;
            transition: ease-in-out 0.5s;
            &:hover{
                background-color: ${buttonBgColorHover};
            }
        }
    }

    .returnMessage{
        text-align: center;
        margin: 0 12px 35px;
        font-weight: 600;
        border: 2px solid #000528;
        padding: 10px;
        border-radius: 6px;
        transition: ease-in-out 0.5s;
    }

    #waitingDiv{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.85);
        z-index: 2;
        transition: ease-in-out 0.5s;
    }

    @media only screen and (max-width: 768px){
        div{
            width: 100%;
        }
    }

    @media only screen and (max-width: 600px){
        div{
            button{
                max-width: none;
            }
        }
    }
`;

const SelectWrapper = styled.span`
  position: relative;
  select {
    cursor: pointer;
    position: relative;
    background: transparent;
    z-index: 2;
  }
  &:after {
    content: '';
    position: absolute;
    right: 20px;
    top: 4px;
    background: url(${ArrowDown});
    background-size: contain;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    z-index: 1;
  }
`;
