import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, ButtonLink } from '../../styles/variables';
import listIcon from '../../assets/images/list.png';
import Loading from '../../components/Loading';
import {Animated} from "react-animated-css";

class HeaderContent extends Component {

  render() {
    const { heading, subheading, buttonLink, buttonText, isCentered, advantagesList, withAnimation = false, compactHeader } = this.props;
    let items = null;
    if(advantagesList){
        items = advantagesList.map(function(item, index){
            return (item !== null) ? <li key={index}>{item}</li> : null;
        });
        if(items !== null){
            items = <ul>{items}</ul>
        }
    }

    return (
      <Holder>
        <Content isCentered={isCentered} compactHeader={compactHeader}>
          {heading ?
            <Animated animationIn={withAnimation ? 'fadeInLeft' : 'fadeIn'} animationOut="fadeOut" isVisible={true}>
              <h1 id="mainHeading">{heading}</h1>
            </Animated> :
            <Loading />
          }
          { subheading ?
              <Animated animationIn={withAnimation ? 'fadeInLeft' : 'fadeIn'} animationOut="fadeOut" animationInDelay="300" isVisible={true}>
                 <p>{subheading}</p>
              </Animated>
               : null
          }
          { buttonLink && buttonText ?
              <Animated animationIn={withAnimation ? 'fadeInLeft' : 'fadeIn'} animationOut="fadeOut" animationInDelay="600" isVisible={true}>
                <ButtonLink className="animation animated " to={buttonLink}>{buttonText}</ButtonLink>
              </Animated>
            : null
          }
          { items }
        </Content>
      </Holder>

    );
  }
}

const Holder = styled(Container)`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    color: #FFF;
    white-space: pre-line;
    min-height: 216px;
    display: flex;
    align-items: center;
  }

  .typingCursor {
    color: #FFF;
  }

  p {
    opacity: 0.7;
    color: #FFF;
    margin-bottom: 40px;
    white-space: pre-line;
  }

  ul {
    list-style-type: none;
    width: 100%;

    li {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: 50%;
        font-size: 22px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.7);
        line-height: 75px;
        &:before{
            content: "";
            display: inline-block;
            background-size: cover;
            vertical-align: middle;
            margin-right: 10px;
            width: 24px;
            height: 18px;
            background: url(${listIcon});
            background-size: cover;
        }
    }

    @media only screen and (max-width: 600px){
      li {
        width: 100%;
        font-size: 17px;
        line-height: 33px;
        text-align: left;
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: ${props => props.isCentered ? 'center' : 'flex-start'};
  flex-direction: column;
  text-align: ${props => props.isCentered ? 'center' : 'left'};
  min-height: 100%;
  margin: ${props => props.compactHeader ? '120px 0' : '200px 0'};
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    margin: 150px 0 80px;
    padding: 0 25px;
  }
  @media only screen and (max-width: 800px) {
    align-items: center;
    text-align: center;
  }
`;

HeaderContent.propTypes = {
  heading: PropTypes.string
};

export default HeaderContent;
