import React, { Component } from 'react';
import Layout from '../Layout/index';
import Header from '../../components/Header/index';
import HeaderContent from '../../components/HeaderContent/index';
import WhiteSection from '../../components/WhiteSection/index';
import CenteredSection from '../../components/CenteredSection/index';
import BlueGradientSection from '../../components/BlueGradientSection';
import HomepageIconsSet from '../../components/HomepageIconsSet';
import Footer from '../../components/Footer';
import TryDemoSection from "../../components/TryDemoSection";

import reducingRiskIcon from '../../components/HomepageIconsSet/img/reducingRisk.png';
import safeWorldIcon from '../../components/HomepageIconsSet/img/safeWorld.png';
import availabilityIcon from '../../components/HomepageIconsSet/img/availability.png';
import cloudSecurityIcon from '../../components/HomepageIconsSet/img/cloud2.png';

import BackgroundImage from '../../assets/images/aboutUs.jpg'
import MemberCard from "../../components/MemberCard";

import { PATH_DEMO } from '../../routes/routePaths';

import { getBlocksByOverFolderId } from '../../functions/blocks';
import Loading from '../../components/Loading';
import SocialImage from '../../assets/images/social.png';
import Helmet from 'react-helmet';
import { MainTitle } from '../../constants/global';

class AboutUs extends Component {

  constructor(props) {
    super(props);
    this.state = {aboutBlocks: undefined};
  }

  componentDidMount = () => {
    getBlocksByOverFolderId(7).then(aboutBlocks => {
      this.setState({aboutBlocks: aboutBlocks.data.data});
    });
  }

  render() {
    const { location } = this.props;
    const { aboutBlocks } = this.state;

    const heading = aboutBlocks ? aboutBlocks.folders[0].blocks[0].content : null;
    const subheading = aboutBlocks ? aboutBlocks.folders[0].blocks[1].content : null;
    const buttonText = aboutBlocks ? aboutBlocks.folders[0].blocks[2].content : null;

    const columns4 = aboutBlocks ? [
      {
        id: 1,
        icon: reducingRiskIcon,
        heading: aboutBlocks.folders[2].blocks[2].name,
        body: aboutBlocks.folders[2].blocks[2].content
      },
      {
        id: 2,
        icon: safeWorldIcon,
        heading: aboutBlocks.folders[2].blocks[3].name,
        body: aboutBlocks.folders[2].blocks[3].content
      },
      {
        id: 3,
        icon: availabilityIcon,
        heading: aboutBlocks.folders[2].blocks[4].name,
        body: aboutBlocks.folders[2].blocks[4].content
      },
      {
        id: 4,
        icon: cloudSecurityIcon,
        heading: aboutBlocks.folders[2].blocks[5].name,
        body: aboutBlocks.folders[2].blocks[5].content
      }
    ] : [];

    return (
      <Layout location={location}>
         <Helmet>
          <meta charSet="utf-8" />
          <title>O nás | {MainTitle}</title>
          <meta property="og:image" content={SocialImage} />
        </Helmet>
        <Header background={BackgroundImage} isBackgroundVideo={false}>
          <HeaderContent
            heading={heading}
            subheading={subheading}
            isCentered={false}
            buttonLink={PATH_DEMO}
            buttonText={buttonText}
          />
        </Header>
        <WhiteSection>
          <CenteredSection>
            <h1>{aboutBlocks ? aboutBlocks.folders[1].blocks[0].content : <Loading />}</h1>
            <p>{aboutBlocks ? aboutBlocks.folders[1].blocks[1].content : null}</p>
            <MemberCard id={1} />
            <MemberCard id={2} orientation="right" />
            <MemberCard id={3} />
          </CenteredSection>
          <BlueGradientSection>
            <HomepageIconsSet columns={columns4} header={aboutBlocks ? aboutBlocks.folders[2].blocks[0].content : null} description={aboutBlocks ? aboutBlocks.folders[2].blocks[1].content : null} />
          </BlueGradientSection>
          <CenteredSection className="boxedContent">
                <h2>{aboutBlocks ? aboutBlocks.folders[1].blocks[2].content : null}</h2>
                <div className="two-column">
                    <p>{aboutBlocks ? aboutBlocks.folders[1].blocks[3].content : null}</p>
                </div>
                <div className="two-column">
                    <p>{aboutBlocks ? aboutBlocks.folders[1].blocks[4].content : null}</p>
                </div>
            </CenteredSection>
        </WhiteSection>
        <Footer>
          <TryDemoSection />
        </Footer>
      </Layout>
    )
  }
}

export default AboutUs;
