export const PATH_HOMEPAGE = '/';
export const PATH_PRODUCT = '/produkt';
export const PATH_SERVICES = '/sluzby';
export const PATH_SOURCES = '/zdroje';
export const PATH_ABOUT = '/o-nas';
export const PATH_DEMO = '/demo';
export const PATH_TOS = '/ochrana-osobnich-udaju';
export const PATH_BLOG = '/blog';
export const PATH_CONTACT = '/kontakt';
export const PATH_STUDY = '/studie';
export const PATH_ARTICLE = '/clanek';
export const PATH_PAGE = '/stranka';
